import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import alertActions from '../../actions/alertActions';

// This will wrap a 'page' component, test for authorisation and either render the page or redirect
const PrivateRoute = ({ component: Component, roles, ...options }) => {
  const { loggedIn } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  return (
    <Route
      {...options}
      render={(props) => {
        let unauthorised = !loggedIn;
        if (unauthorised) {
          dispatch(alertActions.info('Please login to continue...'));
          return (
            <motion.span exit='undefined'>
              <Redirect
                to={{ pathname: '/login', state: { from: props.location } }}
              />
            </motion.span>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PrivateRoute;

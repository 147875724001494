import styled from 'styled-components';

const ProfileRole = styled.div`
  padding-top: 0.2rem;
  padding-left: 4rem;
  text-align: right;
  color: ${props => props.theme.colours.grey};
  transition: 200ms;

  @media(max-width: 550px) {
    font-size: 0.9rem;
  }
`;

export default ProfileRole;

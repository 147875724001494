import styled from 'styled-components';

import { motion } from 'framer-motion';

const Backdrop = styled(motion.div).attrs((props) => ({
  initial: props.initial || { opacity: 0 },
  animate: props.animate || { opacity: 1 },
}))`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zOrder.backdrop};
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export default Backdrop;

import React from 'react';
import { Provider } from 'react-redux';
import store from '../lib/store';
import Layout from '../components/layout/Layout';

import { ThemeProvider } from 'styled-components';
import theme from '../lib/theme';
import GlobalStyle from '../components/util/GlobalStyle';

import AppRoutes from '../routes/AppRoutes';
import Session from '../components/util/Session';

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Session>
          <Layout>
            <AppRoutes />
          </Layout>
        </Session>
      </ThemeProvider>
    </Provider>
  );
}

export default App;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { phaseUp } from '../../lib/animations';

const Phaser = styled(motion.div).attrs(() => ({
  variants: phaseUp,
}))`
  display: block;
`;

export default Phaser;

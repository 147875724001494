import styled from 'styled-components';
import { motion } from 'framer-motion';
import { gameRow } from '../../lib/animations';

const GameRow = styled(motion.div).attrs((props) => ({
  variants: props.variants || gameRow,
}))`
  position: relative;
  background-color: ${(props) => props.theme.colours.darker};
  padding: 0;
  height: 4rem;
  display: flex;
  flex-direction: row;
  width: 100%;
  text-align: center;
  justify-items: center;
  align-items: space-between;
  border-radius: 3px;
  margin-bottom: 0.5rem;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.3);
  border-width: 2px;
  border-color: ${(props) => props.theme.colours.tertiary};
  transition: 200ms;

  @media (max-width: ${(props) => props.theme.breaks.md}) {
    flex-direction: column;
    height: auto;
    padding: 1rem 0.5rem;
  }
`;

export default GameRow;

import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import AppBottomBar from './AppBottomBar';

import styled from 'styled-components';
import { motion } from 'framer-motion';
import Logout from './Logout';

const NavList = styled(motion.ul).attrs(() => ({
  variants: {
    open: {
      transition: { staggerChildren: 0.07, delayChildren: 0.2 },
    },
    closed: {
      transition: { staggerChildren: 0.05, staggerDirection: -1 },
    },
  },
}))`
  display: flex;
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 1rem 0;
  align-items: center;
  justify-content: space-evenly;
  height: 100%;
`;

const NavItem = styled(motion.li).attrs(() => ({
  variants: {
    visible: {
      scale: 1,
      transition: {
        y: { stiffness: 1000, velocity: -100 },
      },
    },
    hidden: {
      scale: 0,
      transition: {
        y: { stiffness: 1000 },
      },
    },
  },
}))`
  flex: 1;
  user-select: none;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  a,
  button {
    color: ${(props) => props.theme.colours.primary};
    border: none;
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: transparent;
    font-size: 1rem;
    transition: 200ms;
    cursor: pointer;
    text-decoration: none;

    &.is-active {
      color: ${(props) => props.theme.colours.light};
      font-size: 1.3rem;
    }

    &:focus {
      outline: none;
      text-decoration: none;
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.1);
      text-decoration: none;
    }

    &:hover {
      color: ${(props) => props.theme.colours.light};
      text-decoration: none;
    }
  }
`;

const BottomNav = () => {
  const { loggedIn } = useSelector((state) => state.user);

  return (
    <AppBottomBar>
      <NavList>
        <NavItem>
          <NavLink to='/' exact activeClassName='is-active'>
            <i className='fas fa-home fa-2x'></i>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to='/games' activeClassName='is-active'>
            <i className='fas fa-list fa-2x'></i>
          </NavLink>
        </NavItem>
        {loggedIn ? (
          <>
            <NavItem>
              <NavLink to='/profile' activeClassName='is-active'>
                <i className='fas fa-user-circle fa-2x'></i>
              </NavLink>
            </NavItem>
            <NavItem>
              <Logout colour='danger'>
                <i className='fas fa-sign-out-alt fa-2x'></i>
              </Logout>
            </NavItem>
          </>
        ) : (
          <NavItem>
            <NavLink
              to='/login'
              name='login'
              className='success'
              activeClassName='is-active'
            >
              <i className='fas fa-sign-in-alt fa-2x'></i>
            </NavLink>
          </NavItem>
        )}
      </NavList>
    </AppBottomBar>
  );
};

export default BottomNav;

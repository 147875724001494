import React from 'react';

import appLogo from '../../assets/images/app-logo.svg';
import styled from 'styled-components';

import { motion } from 'framer-motion';

const AppLoaderContainer = styled(motion.div).attrs(() => ({
  variants: {
    hidden: {
      opacity: 0.05,
      filter: 'blur(8px)',
    },
    visible: {
      opacity: 1,
      filter: 'blur(0px)',
      transition: {
        duration: 1,
        ease: 'easeIn',
      },
    },
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
}))`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zOrder.loadScreen};
  user-select: none;
`;

const AppLoaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: auto;
  margin-bottom: 2rem;

  img {
    display: inline-block;
    text-align: center;
    min-width: 15rem;
    width: 15%;
  }
`;

const AppLoaderLabel = styled.div`
  font-size: 2rem;
  color: ${(props) => props.theme.colours.tertiary};
  font-weight: 400;
  margin: 0;
  padding-left: 2px;
  text-align: center;
  width: 100%;
`;

const AppLoader = (props) => {
  return (
    <AppLoaderContainer>
      <AppLoaderLogo>
        <img src={appLogo} alt='net-realms' />
      </AppLoaderLogo>
      <AppLoaderLabel>net-realms</AppLoaderLabel>
    </AppLoaderContainer>
  );
};

export default AppLoader;

import styled from 'styled-components';

import { motion } from 'framer-motion';
import { page } from '../../lib/animations';

const PageContent = styled(motion.main).attrs((props) => ({
  variants: page,
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}))`
  display: flex;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  overflow: hidden;
  transition: 200ms;

  @media (min-width: ${(props) => props.theme.breaks.sm}) {
    padding-left: 2rem;
    padding-right: 2rem;

    @media (min-width: ${(props) => props.theme.breaks.md}) {
      padding-left: 4rem;
      padding-right: 4rem;

      @media (min-width: ${(props) => props.theme.breaks.lg}) {
        padding-left: 6rem;
        padding-right: 6rem;
      }
    }
  }
`;

export default PageContent;

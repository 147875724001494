import styled from 'styled-components';

import { motion } from 'framer-motion';

const UpdatingMessage = styled(motion.div).attrs(() => ({
  variants: {
    hidden: {
      opacity: 0,
      y: 100,
      transition: {
        duration: 0.5,
        ease: 'easeIn'
      }
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: 'easeOut'
      }
    }
  },
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden'
}))`
  height: auto;
  padding: 1rem;
  font-size: 0.9rem;
  text-align: center;
  transition: 0.2s;
  overflow: hidden;
  width: 100%;
  color: ${props => props.theme.colours.grey}
`;

export default UpdatingMessage;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';

import appActions from '../actions/appActions';
import userActions from '../actions/userActions';

import PageContent from '../components/layout/PageContent';
import StackedForm from '../components/ui/StackedForm';
import TextBox from '../components/ui/TextBox';
import Panel from '../components/ui/Panel';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BigButton from '../components/ui/BigButton';
import Phaser from '../components/ui/Phaser';
import Loader from '../components/loaders/Loader';

const validationSchema = yup.object().shape({
  email: yup.string().email('email is invalid').required('email is required'),
  password: yup
    .string()
    .min(10, 'password must be at least 10 characters')
    .max(32, 'password cannot be longer than 32 characters')
    .required('password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'passwords do not match')
    .required('confirm password is required'),
});

const Reset = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { token } = useParams();
  const [emailField, setEmailField] = useState('');

  const { register, handleSubmit, errors, formState, setValue } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const loading = useSelector((state) => state.reset.loading);
  const success = useSelector((state) => state.reset.success);

  useEffect(() => {
    dispatch(appActions.setPage('Reset'));

    let queryEmailIndex = location.search.indexOf('email=');
    if (queryEmailIndex >= 0) {
      const email = location.search.substr(queryEmailIndex + 6);
      if (email) {
        setEmailField(email);
        setValue('email', email, { shouldValidate: true });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormSubmit(data) {
    const { email, password } = data;

    setEmailField(email);

    dispatch(
      userActions.reset({
        email: email,
        token: token,
        password: password,
      })
    );
  }

  return (
    <PageContent>
      {success ? (
        <Panel>
          <h2 className='text-success'>
            <i className='fas fa-check'></i> Success!
          </h2>
          <p>
            You're all set! Head over the to the{' '}
            <Link to='/login'>Login page</Link> to try it out.
          </p>
        </Panel>
      ) : loading ? (
        <Loader label='Loading...' />
      ) : (
        <Panel>
          <h3>Set your new password!</h3>
          <p>
            If everything checks out, you can choose a new password by filling
            in the details below...
          </p>
          <StackedForm onSubmit={handleSubmit(onFormSubmit)}>
            <TextBox
              name='email'
              disabled={formState.isSubmitting}
              label='email'
              defaultValue={emailField}
              error={errors.email}
              ref={register}
            />
            <TextBox
              name='password'
              type='password'
              disabled={formState.isSubmitting}
              label='password'
              error={errors.password}
              ref={register}
            />
            <TextBox
              name='confirmPassword'
              type='password'
              disabled={formState.isSubmitting}
              label='confirm password'
              error={errors.confirmPassword}
              ref={register}
            />
            <Phaser>
              <BigButton
                type='submit'
                disabled={
                  errors.email ||
                  errors.password ||
                  errors.confirmPassword ||
                  loading
                }
              >
                send reset email
              </BigButton>
            </Phaser>
          </StackedForm>
        </Panel>
      )}
    </PageContent>
  );
};

export default Reset;

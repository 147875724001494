import React from 'react';

import GameItem from './GameItem';

import { motion } from 'framer-motion';
import { container } from '../../lib/animations';
import UpdatingMessage from './UpdatingMessage';

const GameList = (props) => {
  return (
    <motion.div
      className='game-list'
      variants={container}
      initial='hidden'
      animate='visible'
      exit='exit'
      key='games-list'
    >
      {!props.games && <div>No games</div>}
      {props.games &&
        props.games.map((game) => <GameItem key={game.id} game={game} />)}
      {props.fetching ? (
        <UpdatingMessage key='updater'>
          <i className='fas fa-spinner fa-pulse'></i> Updating...
        </UpdatingMessage>
      ) : null}
    </motion.div>
  );
};

export default GameList;

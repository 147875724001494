import React from 'react';
import { NavLink } from 'react-router-dom';
import NavTag from './NavTag';

const NavItem = (props) => {
  return (
    <NavLink
      exact={props.exact}
      activeClassName='is-active'
      to={props.to}
      className={props.className}
      onClick={props.onClick}
    >
      {props.children}
      <NavTag>{props.name}</NavTag>
    </NavLink>
  );
};
export default NavItem;

import React from 'react';
import { useSelector } from 'react-redux';

import BannerAlert from './BannerAlert';

import { AnimatePresence } from 'framer-motion';

const Alert = () => {
  const alert = useSelector((state) => state.alert);

  return (
    <AnimatePresence>
      {alert.message && (
        <BannerAlert className={alert.type}>{alert.message}</BannerAlert>
      )}
    </AnimatePresence>
  );
};

export default Alert;

import React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../components/util/PrivateRoute';

import Overview from '../pages/Overview';
import RealmSelect from '../pages/RealmSelect';

const RealmRoutes = () => {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Switch location={location} key={location.key}>
      <PrivateRoute path={`${match.url}/select`} component={RealmSelect} />
      <PrivateRoute path={`${match.url}/overview`} component={Overview} />
    </Switch>
  );
};

export default RealmRoutes;

import React, { Fragment } from 'react';
import Header from './Header';
import Footer from './Footer';
import Alert from '../ui/Alert';
import SideBar from '../navigation/SideBar';
import BottomNav from '../navigation/BottomNav';

const Layout = (props) => {
  return (
    <Fragment>
      <Alert />
      <SideBar />
      <BottomNav />
      <Header />
      {props.children}
      <Footer />
    </Fragment>
  );
};

export default Layout;

import styled from 'styled-components';

const InputLabel = styled.label`
  user-select: none;
  position: absolute;
  left: 18px;
  right: initial;
  top: 50%;
  transform: ${(props) =>
    props.active === true
      ? 'translateY(-115%) scale(0.75)'
      : 'translateY(-50%)'};
  pointer-events: none;
  transition: 200ms;
  font-size: 1.2rem;
  color: ${(props) => props.theme.colours.grey};
  transform-origin: left top;
  opacity: 1;
`;

export default InputLabel;

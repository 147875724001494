import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import userActions from '../actions/userActions';
import appActions from '../actions/appActions';

import PageContent from '../components/layout/PageContent';
import StackedForm from '../components/ui/StackedForm';
import TextBox from '../components/ui/TextBox';
import Loader from '../components/loaders/Loader';
import ToggleInput from '../components/ui/ToggleInput';
import CheckGroup from '../components/ui/CheckGroup';
import BigButton from '../components/ui/BigButton';
import Phaser from '../components/ui/Phaser';

const validationSchema = yup.object().shape({
  email: yup.string().email('email is invalid').required(),
  password: yup.string().required(),
});

function Login() {
  // Redux
  const loggingIn = useSelector((state) => state.authentication.loggingIn);
  const dispatch = useDispatch();

  // Local state
  const [defaultValues, setDefaultValues] = useState({
    email: '',
    password: '',
    remember: false,
  });

  // Hooks
  const location = useLocation();
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    dispatch(appActions.setPage('Login'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormSubmit(data) {
    const { email, password, remember } = data;

    setDefaultValues({ email, password, remember });

    // get return url from location state or default to home page
    const { from } = location.state || { from: { pathname: '/' } };
    dispatch(userActions.login(email, password, remember, from));
  }

  return (
    <PageContent>
      {loggingIn ? (
        <Loader label='Logging in...' />
      ) : (
        <StackedForm onSubmit={handleSubmit(onFormSubmit)}>
          <TextBox
            name='email'
            disabled={loggingIn}
            label='email'
            defaultValue={defaultValues.email}
            error={errors.email}
            ref={register}
          />
          <TextBox
            name='password'
            type='password'
            disabled={loggingIn}
            label='password'
            defaultValue={defaultValues.password}
            error={errors.password}
            ref={register}
          />
          <CheckGroup>
            <ToggleInput
              id='remember'
              name='remember'
              defaultChecked={defaultValues.remember}
              disabled={loggingIn}
              ref={register}
            />{' '}
            <label htmlFor='remember'>Remember me</label>
          </CheckGroup>
          <Phaser>
            <BigButton
              type='submit'
              disabled={errors.email || errors.password || loggingIn}
            >
              {(loggingIn && <span>loading...</span>) || 'login'}
            </BigButton>
          </Phaser>
          <Phaser>
            <BigButton
              as={Link}
              variant='outline'
              to='/register'
              disabled={loggingIn}
            >
              register
            </BigButton>
          </Phaser>
          <Phaser className='pt-1'>
            Forgot your password?{' '}
            <Link to='/forgot-password'>Reset Password</Link>
          </Phaser>
        </StackedForm>
      )}
    </PageContent>
  );
}

export default Login;

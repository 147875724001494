import styled from 'styled-components';

const TextInput = styled.input`
  position: relative;
  font-family: ${(props) => props.theme.fontFamily};
  width: 100%;
  color: ${(props) => props.theme.colours.light};
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-style: solid;
  border-top-left-radius: ${(props) => props.theme.borderRadius};
  border-top-right-radius: ${(props) => props.theme.borderRadius};
  border-bottom-left-radius: ${(props) => props.theme.borderRadius};
  border-bottom-right-radius: ${(props) => props.theme.borderRadius};
  border-bottom-width: 2px;
  border-top-width: 2px;
  border-left-width: 2px;
  border-right-width: 2px;
  border-color: ${(props) =>
    props.error ? props.theme.colours.danger : props.theme.colours.medium};
  background-color: ${(props) => props.theme.colours.medium};
  font-size: 1.2rem;
  transition: 0.2s;

  &::placeholder {
    font-size: 1.2rem;
    //transform: translateY(-5px);
    color: ${(props) => props.theme.colours.grey};
  }

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colours.primary};
    background-color: ${(props) => props.theme.colours.muted};

    & + label {
      color: ${(props) => props.theme.colours.light};
    }
  }

  &.error {
    border-color: ${(props) => props.theme.colours.danger};
  }
`;

export default TextInput;

import styled from 'styled-components';

import { motion } from 'framer-motion';
import { title } from '../../lib/animations';

const AppTitle = styled(motion.h2).attrs(() => ({
  variants: title,
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}))`
  font-size: 2rem;
  color: ${(props) => props.theme.colours.tertiary};
  font-weight: 400;
  margin: 0;
  padding-left: 2px;
  transition: 200ms;

  @media (max-width: ${(props) => props.theme.breaks.sm}) {
    font-size: 1.5rem;
  }
`;

export default AppTitle;

import gameTypes from './gameTypes';
import gameService from '../services/gameService';
import alertActions from './alertActions';
import userActions from './userActions';
import realmActions from './realmActions';

const gameActions = {
  getAll,
  join,
};

function join(gameId, realmName, rulerName) {
  return (dispatch) => {
    dispatch(request(gameId, realmName, rulerName));

    gameService.join(gameId, realmName, rulerName).then(
      (data) => {
        dispatch(success(data));
        dispatch(
          alertActions.success(`Welcome to ${data.game.name}, ${rulerName}!`)
        );
        dispatch(realmActions.selectRealm(data.realm.id));
      },
      (error) => {
        if (error.status === 401) {
          dispatch(userActions.logout());
        }
        dispatch(failure(error.message));
      }
    );
  };

  function request(gameId, realmName, rulerName) {
    return {
      type: gameTypes.JOIN_REQUEST,
      payload: { gameId, realmName, rulerName },
    };
  }

  function success({ realm }) {
    return { type: gameTypes.JOIN_SUCCESS, payload: { realm } };
  }

  function failure(error) {
    return { type: gameTypes.JOIN_FAILURE, payload: { error } };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    gameService.getAll().then(
      (games) => dispatch(success(games)),
      (error) => {
        if (error.status === 401) {
          dispatch(userActions.logout());
        }
        dispatch(alertActions.error(error.message));
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: gameTypes.GETALL_REQUEST };
  }

  function success(games) {
    return { type: gameTypes.GETALL_SUCCESS, payload: { games } };
  }

  function failure(error) {
    return { type: gameTypes.GETALL_FAILURE, payload: { error } };
  }
}

export default gameActions;

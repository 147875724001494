import styled from 'styled-components';

const Text = styled.p`
  position: relative;
  display: block;
  text-align: center;
  margin: 0 auto;
  ${props => props.bold && 'font-weight: 600;'}
  ${props => {
    if (props.left) return 'text-align: left;';
    if (props.right) return 'text-align: right;';
    else return 'text-align: center;';
  }}

  .text-bold {
    font-weight: 600;
  }
`;

export default Text;

import { combineReducers } from 'redux';

import activation from './activationReducer';
import authentication from './authenticationReducer';
import recover from './recoverReducer';
import reset from './resetReducer';
import registration from './registrationReducer';
import users from './usersReducer';
import alert from './alertReducer';
import app from './appReducer';
import games from './gamesReducer';
import user from './userReducer';
import realm from './realmReducer';
import join from './joinReducer';

const rootReducer = combineReducers({
  activation,
  authentication,
  recover,
  reset,
  registration,
  users,
  alert,
  app,
  games,
  user,
  realm,
  join,
});

export default rootReducer;

import React from 'react';
import { useSelector } from 'react-redux';

import AppSideBar from './AppSideBar';
import NavItem from './NavItem';
import NavDivider from './NavDivider';
import NavSpacer from './NavSpacer';
import NavEnd from './NavEnd';
import Logout from './Logout';

const SideBar = () => {
  const { loggedIn } = useSelector((state) => state.user);
  const gameMode = useSelector(state => state.app.gameNav);

  return (
    <AppSideBar>
      <NavItem exact to='/' name='home'>
        <i className='fas fa-home fa-2x'></i>
      </NavItem>
      <NavDivider></NavDivider>
      {gameMode ? (
        <NavItem to='/realm/overview' name='overview'>
          <i className='fab fa-fort-awesome-alt fa-2x'></i>
        </NavItem>
      ) : (
        <NavItem to='/games' name='games'>
          <i className='fas fa-list fa-2x'></i>
        </NavItem>)}
      <NavEnd>
        {loggedIn ? (
          <>
            <NavItem to='/profile' name='profile'>
              <i className='fas fa-user-circle fa-2x'></i>
            </NavItem>
            <Logout className='tagged'>
              <i className='fas fa-sign-out-alt fa-2x text-danger'></i>
            </Logout>
          </>
        ) : (
          <NavItem to='/login' name='login' className='success'>
            <i className='fas fa-sign-in-alt fa-2x text-success'></i>
          </NavItem>
        )}
        <NavSpacer />
      </NavEnd>
    </AppSideBar>
  );
};

export default SideBar;

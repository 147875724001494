import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import appActions from '../actions/appActions';

import { motion } from 'framer-motion';
import PageContent from '../components/layout/PageContent';
import Panel from '../components/ui/Panel';
import Phaser from '../components/ui/Phaser';

const RegisterSuccess = () => {
  // Hooks
  const dispatch = useDispatch();
  const registered = useSelector((state) => state.registration.registered);

  useEffect(() => {
    dispatch(appActions.setPage('Success!'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!registered) {
    return (
      <motion.span exit='undefined'>
        <Redirect to={{ pathname: '/' }} />
      </motion.span>
    );
  }

  return (
    <PageContent>
      <Panel>
        <h2>Registration successful...</h2>
        <p>
          Before you can log in you'll need to check your email for an
          activation code to confirm your account.
        </p>
        <Phaser className='pt-1'>
          Already activated? <Link to='/login'>Login</Link>
        </Phaser>
      </Panel>
    </PageContent>
  );
};

export default RegisterSuccess;

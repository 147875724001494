import React from 'react';
import { Switch, useLocation, Route, Redirect } from 'react-router-dom';

import PublicRoute from '../components/util/PublicRoute';
import PrivateRoute from '../components/util/PrivateRoute';

import Home from '../pages/Home';
import Login from '../pages/Login';
import Register from '../pages/Register';
import RegisterSuccess from '../pages/RegisterSuccess';
import Activate from '../pages/Activate';
import Reset from '../pages/Reset';
import Forgot from '../pages/Forgot';
import Profile from '../pages/Profile';

import GameRoutes from './GameRoutes';

import { AnimatePresence, motion } from 'framer-motion';
import RealmRoutes from './RealmRoutes';

const AppRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Switch location={location} key={location.key}>
        <Route path='/' exact component={Home} />
        <PublicRoute path='/login' component={Login} />
        <PublicRoute path='/register/success' component={RegisterSuccess} />
        <PublicRoute path='/register' component={Register} />
        <Route path='/activate/:token?' component={Activate} />
        <Route path='/reset-password/:token' component={Reset} />
        <Route path='/forgot-password' component={Forgot} />
        <PrivateRoute path='/profile' component={Profile} />
        <Route path='/games'>
          <GameRoutes />
        </Route>
        <Route path='/realm'>
          <RealmRoutes />
        </Route>
        {/* Required for framer motion */}
        <motion.span exit='undefined'>
          <Redirect from='*' to='/' />
        </motion.span>
      </Switch>
    </AnimatePresence>
  );
};

export default AppRoutes;

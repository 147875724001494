import axios from '../lib/axios';

const gameService = {
  getAll,
  findById,
  join,
};

async function getAll() {
  try {
    const response = await axios.get('/games');
    return response.data.games.sort((a, b) => {
      if (a.endDate === null) {
        if (b.endDate === null) {
          return 0;
        }
        return -1;
      } else if (b.endDate === null) {
        return 1;
      }

      return a.endDate - b.endDate;
    });
  } catch (error) {
    return handleErrors(error);
  }
}

async function findById(id) {
  try {
    const response = await axios.get(`/games/${id}`);
    return response.data.game;
  } catch (error) {
    return handleErrors(error);
  }
}

async function join(id, realmName, rulerName) {
  return axios
    .post(`/games/${id}/register`, { realmName, rulerName })
    .then((response) => {
      return response.data;
    })
    .catch(handleErrors);
}

function handleErrors(error) {
  if (error.response === undefined) {
    return Promise.reject({
      status: 500,
      message: 'Error communicating with server...',
    });
  }

  const errorMessage =
    error.response.data?.message || error?.message || error.response.statusText;

  return Promise.reject({
    status: error.response.status,
    message: errorMessage,
  });
}

export default gameService;

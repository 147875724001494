import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../actions/appActions';

import PageContent from '../components/layout/PageContent';
import Row from '../components/ui/Row';
import ProfileAvatar from '../components/profile/ProfileAvatar';
import ProfileHeader from '../components/profile/ProfileHeader';

import profile from '../assets/images/profile-512.png';
import Panel from '../components/ui/Panel';

const Profile = () => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState(profile);

  useEffect(() => {
    dispatch(appActions.updateTitle('Profile'));
    if (user.avatar) {
      setProfileImage(user.avatar);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent key='profile-content'>
      <Row>
        <ProfileAvatar>
          <img src={profileImage} alt='profile' />
        </ProfileAvatar>
        <ProfileHeader name={user.displayName} role={user.role} />
      </Row>
      <Panel className='mt-2'>
        <h2>Settings</h2>
      </Panel>
    </PageContent>
  );
};

export default Profile;

import React, { useEffect, useState } from 'react';

import Backdrop from './Backdrop';
import DialogBox from './DialogBox';
import DialogButton from './DialogButton';
import MessageText from './MessageText';

import { AnimatePresence } from 'framer-motion';
import usePortal from '../hooks/usePortal';
import { createPortal } from 'react-dom';

const Dialog = (props) => {
  const target = usePortal();
  const [successText, setSuccessText] = useState('Confirm');
  const [dangerText, setDangerText] = useState('Cancel');

  useEffect(() => {
    setSuccessText(props.successText);
    setDangerText(props.dangerText);
  }, [props.successText, props.dangerText]);

  function onSuccess(e) {
    props.onSuccess && props.onSuccess();
    props.onClose();
  }

  function onDanger(e) {
    props.onDanger && props.onDanger();
    props.onClose();
  }

  return createPortal(
    <>
      {props.show ? (
        <Backdrop show={props.show} onClick={() => props.onClose()} />
      ) : null}
      <AnimatePresence exitBeforeEnter>
        {props.show && (
          <DialogBox>
            <h2>{props.title}</h2>
            <MessageText>{props.message}</MessageText>
            <div>
              <DialogButton
                variant='solid'
                colour='success'
                onClick={onSuccess}
              >
                {successText}
              </DialogButton>
              <DialogButton variant='solid' colour='danger' onClick={onDanger}>
                {dangerText}
              </DialogButton>
            </div>
          </DialogBox>
        )}
      </AnimatePresence>
    </>,
    target
  );
};

export default Dialog;

const userTypes = {
  RESTORE_REQUEST: 'USERS_RESTORE_REQUEST',
  RESTORE_SUCCESS: 'USERS_RESTORE_SUCCESS',
  RESTORE_FAILURE: 'USERS_RESTORE_FAILURE',

  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  RECOVER_REQUEST: 'USERS_RECOVER_REQUEST',
  RECOVER_SUCCESS: 'USERS_RECOVER_SUCCESS',
  RECOVER_FAILURE: 'USERS_RECOVER_FAILURE',

  RESET_REQUEST: 'USERS_RESET_REQUEST',
  RESET_SUCCESS: 'USERS_RESET_SUCCESS',
  RESET_FAILURE: 'USERS_RESET_FAILURE',

  ACTIVATE_REQUEST: 'USERS_ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'USERS_ACTIVATE_SUCCESS',
  ACTIVATE_FAILURE: 'USERS_ACTIVATE_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  GETALL_REQUEST: 'USERS_GETALL_REQUEST',
  GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
  GETALL_FAILURE: 'USERS_GETALL_FAILURE',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  REALMS_REQUEST: 'USERS_REALMS_REQUEST',
  REALMS_SUCCESS: 'USERS_REALMS_SUCCESS',
  REALMS_FAILURE: 'USERS_REALMS_FAILURE',
};

export default userTypes;

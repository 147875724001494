import styled, { keyframes } from 'styled-components';

const LoadBarAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
`;

const LoadBars = styled.div`
  &,
  &:before,
  &:after {
    background: ${(props) => props.theme.colours.light};
    animation: ${LoadBarAnimation} 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }
  & {
    color: ${(props) => props.theme.colours.light};
    text-indent: -9999em;
    margin: 78px auto;
    position: relative;
    font-size: 11px;
    transform: translateZ(0);
    animation-delay: -0.16s;
  }
  &:before,
  &:after {
    position: absolute;
    top: 0;
    content: '';
  }
  &:before {
    left: -1.5em;
    animation-delay: -0.32s;
  }
  &:after {
    left: 1.5em;
  }
`;

export default LoadBars;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { phaseUp } from '../../lib/animations';

const CheckGroup = styled(motion.div).attrs((props) => ({
  variants: props.variants || phaseUp,
}))`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 3rem;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  @media (max-width: ${(props) => props.theme.breaks.md}) {
    flex-direction: column;
  }
`;

export default CheckGroup;

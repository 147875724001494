import userTypes from '../actions/userTypes';

const initialState = { loading: false };

export default function users(state = initialState, { type, payload }) {
  switch (type) {
    case userTypes.GETALL_REQUEST:
      return {
        loading: true,
      };
    case userTypes.GETALL_SUCCESS:
      return {
        items: payload.users,
        loading: false,
      };
    case userTypes.GETALL_FAILURE:
      return {
        error: payload.error,
        loading: false,
      };
    case userTypes.DELETE_REQUEST:
      // add 'deleting:true' property to user being deleted
      return {
        ...state,
        items: state.items.map((user) =>
          user.id === payload.id ? { ...user, deleting: true } : user
        ),
      };
    case userTypes.DELETE_SUCCESS:
      // remove deleted user from state
      return {
        loading: false,
        items: state.items.filter((user) => user.id !== payload.id),
      };
    case userTypes.DELETE_FAILURE:
      // remove 'deleting:true' property and add 'deleteError:[error]' property to user
      return {
        ...state,
        items: state.items.map((user) => {
          if (user.id === payload.id) {
            // make copy of user without 'deleting:true' property
            const { deleting, ...userCopy } = user;
            // return copy of user with 'deleteError:[error]' property
            return { ...userCopy, deleteError: payload.error };
          }

          return user;
        }),
      };
    default:
      return state;
  }
}

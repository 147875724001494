const realmTypes = {
  SET_ACTIVE: 'REALM_SET_ACTIVE',

  SUMMARY_REQUEST: 'REALM_SUMMARY_REQUEST',
  SUMMARY_SUCCESS: 'REALM_SUMMARY_SUCCESS',
  SUMMARY_FAILURE: 'REALM_SUMMARY_FAILURE',

  CURRENT_REQUEST: 'REALM_CURRENT_REQUEST',
  CURRENT_SUCCESS: 'REALM_CURRENT_SUCCESS',
  CURRENT_FAILURE: 'REALM_CURRENT_FAILURE',
};

export default realmTypes;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import appActions from '../actions/appActions';
import userActions from '../actions/userActions';

import PageContent from '../components/layout/PageContent';
import Text from '../components/ui/Text';
import TextBox from '../components/ui/TextBox';
import Loader from '../components/loaders/Loader';
import BigButton from '../components/ui/BigButton';
import StackedForm from '../components/ui/StackedForm';
import Phaser from '../components/ui/Phaser';

const validationSchema = yup.object().shape({
  displayName: yup
    .string()
    .matches(/^[\w\-\s]+$/, 'may only contain a-z, 0-9, -, _ and spaces')
    .min(3, 'display name must be at least 3 characters')
    .max(30, 'display name cannot exceed 30 characters')
    .required('display name is required'),
  email: yup.string().email('email is invalid').required('email is required'),
  password: yup
    .string()
    .min(10, 'password must be at least 10 characters')
    .max(32, 'password cannot be longer than 32 characters')
    .required('password is required'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password')], 'passwords do not match')
    .required('confirm password is required'),
});

const Register = () => {
  const dispatch = useDispatch();
  const registering = useSelector((state) => state.registration.registering);

  // State
  const [user, setUser] = useState({
    displayName: '',
    email: '',
  });

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Events
  function onFormSubmit(data) {
    const { displayName, email, password } = data;

    setUser({
      displayName: displayName,
      email: email,
    });

    dispatch(
      userActions.register({
        displayName: displayName,
        email: email,
        password: password,
      })
    );
  }

  useEffect(() => {
    dispatch(appActions.setPage('Register'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent>
      <p>
        Register for Net-Realms below. You will need to verify your email before
        you can login.
      </p>
      {registering ? (
        <Loader label='Registering...' />
      ) : (
        <StackedForm onSubmit={handleSubmit(onFormSubmit)}>
          <TextBox
            name='displayName'
            disabled={registering}
            label='display name'
            defaultValue={user.displayName}
            error={errors.displayName}
            ref={register}
          />
          <TextBox
            name='email'
            disabled={registering}
            defaultValue={user.email}
            label='email'
            error={errors.email}
            ref={register}
          />
          <TextBox
            name='password'
            type='password'
            disabled={registering}
            label='password'
            error={errors.password}
            ref={register}
          />
          <TextBox
            name='confirmPassword'
            type='password'
            disabled={registering}
            label='confirm password'
            error={errors.confirmPassword}
            ref={register}
          />
          <Phaser>
            <BigButton type='submit' disabled={registering} className='mt-1'>
              {(registering && <span>loading...</span>) || 'register'}
            </BigButton>
          </Phaser>
        </StackedForm>
      )}
      <Text>
        <span className='text-bold'>Already registered?</span>{' '}
        <Link to='/login'>Login</Link>
      </Text>
    </PageContent>
  );
};

export default Register;

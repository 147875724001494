import styled from 'styled-components';

const NavEnd = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export default NavEnd;



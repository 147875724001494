import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { motion } from 'framer-motion';

// This will wrap a 'page' component, test for authorisation and either render the page or redirect
const PublicRoute = ({ component: Component, ...options }) => {
  const loggedIn = useSelector((state) => state.user.loggedIn);

  return (
    <Route
      {...options}
      render={(props) => {
        if (loggedIn) {
          return (
            <motion.span exit='undefined'>
              <Redirect
                to={{ pathname: '/', state: { from: props.location } }}
              />
            </motion.span>
          );
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;

import styled from 'styled-components';

const ToggleInput = styled.input.attrs(() => ({
  type: 'checkbox',
}))`
  width: 2rem;
  height: 1.5rem;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: ${(props) => props.theme.borderRadius};
  border-bottom-width: 1px;
  border-top-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-style: solid;
  border-color: ${(props) => props.theme.colours.muted};

  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colours.primary};
  }

  &:empty {
    margin-left: -9999px;
    opacity: 0;
    position: absolute;

    & ~ label {
      position: relative;
      cursor: pointer;
      user-select: none;
      padding-left: 3rem;

      &:before,
      &:after {
        position: absolute;
        display: block;
        content: ' ';
        transition: all 200ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      &:before {
        top: 3px;
        left: 0px;
        width: 32px;
        height: 13px;
        border-radius: 12px;
        background-color: ${(props) => props.theme.colours.muted};
      }

      &:after {
        top: -1px;
        left: -9px;
        bottom: 0.1rem;
        margin-left: 0.1rem;
        background-color: ${(props) => props.theme.colours.light};
        border-radius: 50%;
        width: 21px;
        height: 21px;
        border-radius: 50%;
        border: solid 2px;
        border-color: ${(props) => props.theme.colours.light};
      }
    }
  }

  label {
    user-select: none;
  }

  &:checked ~ label {
    padding-left: 3rem;

    &:before {
      background-color: ${(props) => props.theme.colours.tertiary};
    }

    &:after {
      left: 15px;
      background-color: ${(props) => props.theme.colours.primary};
      border-color: ${(props) => props.theme.colours.primary};
    }
  }
`;

export default ToggleInput;

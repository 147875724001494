import styled from 'styled-components';

const LoadContainer = styled.div`
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.theme.zOrder.loadScreen};
  user-select: none;
`;

export default LoadContainer;

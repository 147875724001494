import styled from 'styled-components';

const NavSpacer = styled.div`
  height: 1rem;
  min-height: 1rem;
  max-height: 1rem;
  width: 100%;
`;

export default NavSpacer;



import React from 'react';
import styled from 'styled-components';
import Button from '../ui/Button';

const Item = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.8rem 1rem;
  background-color: ${props => props.theme.colours.darker};
  border-radius: 5px;
  margin-bottom: 0.5rem;
  box-shadow: ${props => props.theme.boxShadow};
  align-items: center;

  div {
    flex: 1;

    &:last-child {
      text-align: right;
    }
  }
`;

const RealmSelectItem = (props) => {
  const realm = props.realm;
  const selected = props.selected;

  return (
    <Item>
      <div>{realm.ownerName} of {realm.name}</div>
      <div>{realm.game.name}</div>
      <div>{selected ? '(Selected)' : ''}</div>
      <div><Button onClick={() => props.onSelect(realm.id)}>Select</Button></div>
    </Item>
  )
}

export default RealmSelectItem

import styled from 'styled-components';

const TitleBlock = styled.span`
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
`;

export default TitleBlock;

import gameTypes from '../actions/gameTypes';

const initialState = { items: [], loading: false, updated: null, error: null };

export default function game(state = initialState, { type, payload }) {
  switch (type) {
    case gameTypes.GETALL_REQUEST:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case gameTypes.GETALL_SUCCESS:
      return {
        items: payload.games,
        loading: false,
        updated: Date.now(),
      };
    case gameTypes.GETALL_FAILURE:
      return {
        items: [],
        loading: false,
        error: payload.error,
        updated: null,
      };
    default:
      return state;
  }
}

import React from 'react';

import LoaderContainer from './LoaderContainer';
import LoaderContent from './LoaderContent';
import LoaderBars from './LoadBars';

const Loader = (props) => {
  return (
    <LoaderContainer>
      <LoaderContent>
        <LoaderBars></LoaderBars>
        {props.label ? <div className='label'>{props.label}</div> : undefined}
      </LoaderContent>
    </LoaderContainer>
  );
};

export default Loader;

import styled from 'styled-components';

const GameCell = styled.div.attrs((props) => ({
  colour: props.colour || props.theme.colours.light,
}))`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  justify-content: center;
  text-align: left;
  padding: 0 0.5rem;
  color: ${(props) => props.theme.colours[props.colour]};

  &.game-id {
    font-weight: 600;
    padding-left: 1rem;
  }

  &.game-name {
    flex: 3;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 4rem;
    padding-top: 1.4rem;
    padding-bottom: 0.5rem;
  }

  &.game-realms {
    flex: 1;
    font-size: 0.9rem;
  }

  &.game-period {
    display: flex;
    flex-direction: row;
    flex: 2;
    font-size: 0.9rem;
    justify-content: space-around;
    min-width: 250px;

    .game-start,
    .game-end {
      display: flex;
      flex-direction: column;
      padding-top: 0.7rem;
      width: 100%;
    }

    .label {
      color: ${(props) => props.theme.colours.grey};
    }
  }

  &.game-status {
    flex: 1;
    font-size: 0.9rem;
  }

  &.game-actions {
    padding-right: 1rem;
    min-width: 8rem;
  }

  @media (max-width: ${(props) => props.theme.breaks.md}) {
    &.game-id {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }

    &.game-name {
      padding-top: 0;
    }

    &.game-status {
      padding-top: 0.2rem;
    }

    &.game-period {
      padding-top: 0.5rem;
      padding-left: 0.5rem;
      justify-items: flex-start;
      justify-content: flex-start;
      align-items: flex-start;

      .game-start,
      .game-end {
        display: block;
        padding-left: 0;
        padding-right: 1rem;
      }
    }

    &.game-actions {
      padding-top: 1rem;
      min-width: auto;
      padding-right: 0.5rem;
    }
  }
`;

export default GameCell;

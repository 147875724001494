import userTypes from '../actions/userTypes';

const initialState = {
  hydrated: false,
  loading: false,
  realms: [],
  error: null,
  loggedIn: false,
};

export default function user(state = initialState, { type, payload }) {
  switch (type) {
    case userTypes.RESTORE_REQUEST:
      return { ...state, loading: true, error: null };
    case userTypes.RESTORE_SUCCESS:
    case userTypes.LOGIN_SUCCESS:
      const session = window.localStorage.getItem('session') || Date.now();
      window.localStorage.setItem('session', session);
      return {
        ...state,
        ...payload.user,
        loading: false,
        hydrated: true,
        error: null,
      };
    case userTypes.RESTORE_FAILURE:
      return { ...state, error: payload.error, loading: false, hydrated: true };
    case userTypes.LOGOUT:
      window.localStorage.removeItem('session');
      return { ...initialState, hydrated: true, loggedIn: false };
    case userTypes.REALMS_REQUEST:
      return { ...state, loading: true, error: null, hydrated: true };
    case userTypes.REALMS_SUCCESS:
      return { ...state, realms: payload.realms, hydrated: true };
    case userTypes.REALMS_FAILURE:
      return { ...state, realms: [], error: payload.error, hydrated: true };
    default:
      return state;
  }
}

import realmTypes from './realmTypes';
import realmService from '../services/realmService';
import userActions from './userActions';
import history from '../lib/history';

const realmActions = {
  setActive,
  getSummary,
  getCurrent,
  selectRealm,
};

function setActive(realmId) {
  return {
    type: realmTypes.SET_ACTIVE,
    payload: { realmId },
  };
}

function selectRealm(realmId) {
  return (dispatch) => {
    dispatch(setActive(realmId));
    history.push('/realm/overview');
  };
}

function getSummary(realmId) {
  return (dispatch) => {
    dispatch(request(realmId));

    realmService.getSummary(realmId).then(
      (realm) => {
        dispatch(success(realm));
      },
      (error) => {
        if (error.status === 401) {
          dispatch(userActions.logout());
        }
        dispatch(failure(error.message));
      }
    );
  };

  function request(realmId) {
    return { type: realmTypes.SUMMARY_REQUEST, payload: { realmId } };
  }
  function success(realm) {
    return { type: realmTypes.SUMMARY_SUCCESS, payload: { realm } };
  }
  function failure(error) {
    return { type: realmTypes.SUMMARY_FAILURE, payload: { error } };
  }
}

function getCurrent(userId) {
  return (dispatch) => {
    dispatch(request(userId));

    realmService.getActiveRealm(userId).then(
      (realm) => {
        dispatch(success(realm));
      },
      (error) => {
        if (error.status === 401) {
          dispatch(userActions.logout());
        }
        dispatch(failure(error.message));
      }
    );
  };

  function request(userId) {
    return { type: realmTypes.CURRENT_REQUEST, payload: { userId } };
  }
  function success(realm) {
    return { type: realmTypes.CURRENT_SUCCESS, payload: { realm } };
  }
  function failure(error) {
    return { type: realmTypes.CURRENT_FAILURE, payload: { error } };
  }
}

export default realmActions;

import styled from 'styled-components';

const NavDivider = styled.div`
  height: 2px;
  background-color: ${props => props.theme.colours.navSelect};
  min-height: 2px;
  max-height: 2px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export default NavDivider;



import React, { forwardRef } from 'react';
import InputGroup from './InputGroup';
import InputLabel from './InputLabel';
import TextInput from './TextInput';
import ValidationError from './ValidationError';

const TextBox = forwardRef((props, ref) => {
  return (
    <InputGroup>
      <TextInput
        id={props.name}
        type={props.type || 'text'}
        name={props.name}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        value={props.value}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        error={props.error}
        disabled={props.disabled || false}
        ref={ref}
      />
      <InputLabel htmlFor={props.name} active={true}>
        {props.label}
      </InputLabel>
      {props.error && <ValidationError>{props.error.message}</ValidationError>}
    </InputGroup>
  );
});

export default TextBox;

import styled from 'styled-components';

import { motion } from 'framer-motion';
import { sideBar } from '../../lib/animations';

const AppSideBar = styled(motion.nav).attrs((props) => ({
  variants: sideBar,
  initial: 'hidden',
  animate: 'visible',
  exit: 'hidden',
}))`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: flex-start;
  align-items: center;
  z-index: ${(props) => props.theme.zOrder.sideBar};
  background-color: ${(props) => props.theme.colours.darker};
  height: 100%;
  width: 4rem;
  box-shadow: 1px 0 3px 3px rgba(0, 0, 0, 30%);
  transition: 0.2s;

  button,
  a {
    width: 100%;
    color: ${(props) => props.theme.colours.muted};
    height: 4rem;
    display: flex;
    justify-content: flex-start;
    padding-left: 0.9rem;
    text-align: left;
    align-items: center;
    cursor: pointer;
    border-width: 2px;
    border-top-style: none;
    border-left-style: solid;
    border-right-style: none;
    border-bottom-style: none;
    border-color: transparent;
    background-color: transparent;
    font-size: 1rem;

    &:focus,
    &:active {
      outline: none;
    }

    &:active {
      background-color: ${(props) => props.theme.colours.dark};
    }

    &:hover {
      border-left-color: ${(props) => props.theme.colours.primary};
      text-decoration: none;
      color: ${(props) => props.theme.colours.primary};

      > span {
        opacity: 1;
        transform: translateX(60%) scale(1);
      }
    }

    &.danger {
      color: ${(props) => props.theme.colours.danger};

      &:hover {
        color: #ffffff;
      }
    }

    &.success {
      color: ${(props) => props.theme.colours.success};

      &:hover {
        color: #ffffff;
      }
    }

    &.is-active {
      color: ${(props) => props.theme.colours.light};
      border-color: ${(props) => props.theme.colours.primary};
      background-color: ${(props) => props.theme.colours.navSelect};
    }
  }

  ~ header,
  ~ footer,
  ~ main {
    @media (min-width: ${(props) => props.theme.breaks.sm}) {
      margin-left: 4rem !important;
    }
  }

  @media (max-width: ${(props) => props.theme.breaks.sm}) {
    left: -4.5rem;
  }
`;

export default AppSideBar;

import realmTypes from '../actions/realmTypes';

const initialState = { loading: false, updated: null, error: null, selected: null, summary: null };

export default function realm(state = initialState, { type, payload }) {
  switch (type) {
    case realmTypes.SET_ACTIVE:
      return { ...state, selected: payload.realmId, error: null };
    case realmTypes.CURRENT_REQUEST:
      return {
        ...state,
        requested: payload.userId,
        error: null,
        loading: true,
      };
    case realmTypes.CURRENT_SUCCESS:
      return {
        ...state,
        selected: payload.realm.id,
        summary: payload.realm,
        error: null,
        loading: false,
        updated: Date.now()
      };
    case realmTypes.CURRENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case realmTypes.SUMMARY_REQUEST:
      return {
        ...state,
        requested: payload.realmId,
        error: null,
        loading: true,
      };
    case realmTypes.SUMMARY_SUCCESS:
      return {
        ...state,
        summary: payload.realm,
        error: null,
        loading: false,
        updated: Date.now()
      };
    case realmTypes.SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

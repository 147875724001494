import styled from 'styled-components';

const AppHeader = styled.header`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  transition: 200ms;

  @media (min-width: ${(props) => props.theme.breaks.sm}) {
    padding-left: 2rem;
    padding-right: 2rem;

    @media (min-width: ${(props) => props.theme.breaks.md}) {
      padding-left: 4rem;
      padding-right: 4rem;

      @media (min-width: ${(props) => props.theme.breaks.lg}) {
        padding-left: 6rem;
        padding-right: 6rem;
      }
    }
  }
`;

export default AppHeader;

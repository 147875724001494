import alertTypes from './alertTypes';

const alertActions = {
  success,
  error,
  info,
  clear,
};

export function success(message) {
  return show(alertTypes.SUCCESS, message);
}

function error(message) {
  return show(alertTypes.ERROR, message);
}

function info(message) {
  return show(alertTypes.INFO, message);
}

function clear() {
  return { type: alertTypes.CLEAR };
}

function show(type, message) {
  return (dispatch) => {
    setTimeout(() => {
      dispatch(clear());
    }, 5000);
    dispatch({ type: type, payload: { message } });
  };
}

export default alertActions;

import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import alertActions from '../../actions/alertActions';

import GameRow from './GameRow';
import GameCell from './GameCell';
import Button from '../ui/Button';
import { Link } from 'react-router-dom';
import realmActions from '../../actions/realmActions';

const GameItem = (props) => {
  const dispatch = useDispatch();

  const selectedRealm = useSelector((state) => state.realm.realmId);

  function formatDate(date) {
    if (date === null) {
      return '-';
    }

    return new Intl.DateTimeFormat('en-AU', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
    })
      .format(new Date(date))
      .toString();
  }

  function onClick(e) {
    dispatch(
      alertActions.info("Yeah... this doesn't do anything yet... sorry.")
    );
  }

  function selectRealm(realmId) {
    if (selectedRealm !== realmId) {
      dispatch(realmActions.selectRealm(realmId));
    }
  }

  return (
    <GameRow>
      <GameCell
        className='game-id'
        colour='tertiary'
      >{`#${props.game.id}`}</GameCell>
      <GameCell className='game-name'>{props.game.name}</GameCell>
      <GameCell className='game-realms' colour='tertiary'>
        {props.game.realmCount} realms
      </GameCell>
      <GameCell
        className='game-status'
        colour={props.game.open ? 'success' : 'danger'}
      >
        {props.game.open ? 'Open' : 'Closed'}
      </GameCell>
      <GameCell className='game-period'>
        <div className='game-start'>
          <div className='label'>starts</div>
          <div className='value'>{formatDate(props.game.startDate)}</div>
        </div>
        <div className='game-end'>
          <div className='label'>ends</div>
          <div className='value'>{formatDate(props.game.endDate)}</div>
        </div>
      </GameCell>
      <GameCell className='game-actions'>
        {props.game.registered && props.game.open ? (
          <Button
            onClick={() => selectRealm(props.game.registered.id)}
            colour='success'
          >
            view
          </Button>
        ) : props.game.open ? (
          <Button
            as={Link}
            colour='primary'
            to={`/games/${props.game.id}/join`}
          >
            join
          </Button>
        ) : (
          <Button colour='tertiary' onClick={onClick}>
            results
          </Button>
        )}
      </GameCell>
    </GameRow>
  );
};

export default GameItem;

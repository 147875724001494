import axios from '../lib/axios';

const realmService = {
  getSummary,
  getActiveRealm,
};

async function getSummary(id) {
  try {
    const response = await axios.get(`/realms/${id}/summary`);
    return response.data.realm;
  } catch (error) {
    return handleErrors(error);
  }
}

async function getActiveRealm(userId) {
  try {
    const response = await axios.get(`/users/${userId}/realm`);
    return response.data.realm;
  } catch (error) {
    return handleErrors(error);
  }
}

function handleErrors(error) {
  if (error.response === undefined) {
    return Promise.reject({
      status: 500,
      message: 'Error communicating with server...',
    });
  }

  const errorMessage =
    error.response.data?.message || error?.message || error.response.statusText;

  return Promise.reject({
    status: error.response.status,
    message: errorMessage,
  });
}

export default realmService;

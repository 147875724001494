import userTypes from '../actions/userTypes';

const initialState = { loading: false };

export default function activation(state = initialState, { type, payload }) {
  switch (type) {
    case userTypes.ACTIVATE_REQUEST:
      return { loading: true };
    case userTypes.ACTIVATE_SUCCESS:
      return { activated: true, loading: false };
    case userTypes.ACTIVATE_FAILURE:
      return { error: payload.error, loading: false };
    default:
      return state;
  }
}

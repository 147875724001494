import React from 'react';
import styled from 'styled-components';

const StyledResourceItem = styled.div.attrs(props => ({ colour: props.colour || 'light' }))`
  width: 100%;
  display: flex;
  position: relative;
  flex-direction: row;
  padding: 1rem;
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colours.light};
  border-bottom: 1px solid ${props => props.theme.colours.grey};
  font-size: 1.2rem;

  .icon {
    width: 2rem;
  }

  .resource {
    flex: 5;
  }

  .value {
    font-weight: 600;
    flex: 1;
    max-width: 6rem;
    text-align: right;
    padding-right: 1rem;
    color: ${props => props.theme.colours[props.colour]};
  }
`;

const ResourceItem = (props) => {
  return (
    <StyledResourceItem colour={props.value > 0 ? 'success' : (props.value === 0 ? 'grey' : 'danger')}>
      <div className='icon'>{props.icon}</div>
      <div className='resource'>{props.name}</div>
      <div className='value'>{Math.sign(props.value) === 1 ? '+' : (Math.sign(props.value) === -1 ? '-' : '')}{props.value}</div>
    </StyledResourceItem>
  );
};

export default ResourceItem;

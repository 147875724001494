import styled, { css } from 'styled-components';

const Button = styled.button.attrs((props) => ({
  variant: props.variant || 'solid',
  colour: props.colour || 'primary',
}))`
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  border-style: solid;
  border-width: 2px;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  transition: 100ms;
  user-select: none;
  vertical-align: middle;
  font-family: ${(props) => props.theme.fontFamily};
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 20%);

  ${({ theme, variant, colour }) =>
    variant &&
    theme.buttons[variant][colour] &&
    css`
      border-color: ${theme.buttons[variant][colour].border};
      background-color: ${theme.buttons[variant][colour].background};
      color: ${theme.buttons[variant][colour].text};

      &:hover {
        text-decoration: none;
        border-color: ${theme.buttons[variant][colour].hover.border};
        background-color: ${theme.buttons[variant][colour].hover.background};
        color: ${theme.buttons[variant][colour].hover.text};
      }

      &:active {
        border-color: ${theme.buttons[variant][colour].active.border};
        background-color: ${theme.buttons[variant][colour].active.background};
        box-shadow: 0 0 0 3px ${theme.buttons[variant][colour].active.shadow};,
          inset 0 3px 5px rgba(0, 0, 0, 0.125);
      }

      &:focus {
        outline: 0;
        box-shadow: 0 0 0 3px rgba(189, 147, 249, 0.25);
      }
    `}

  &:disabled,
  .disabled {
    pointer-events: none;
    border-color: ${(props) => props.theme.colours.muted};
    background-color: ${(props) => props.theme.colours.muted};
    color: ${(props) => props.theme.colours.dark};
    box-shadow: none;
  }
`;

export default Button;

import {
  getLuminance,
  lighten,
  darken,
  transparentize,
  desaturate,
} from 'polished';

const colours = {
  primary: 'rgb(189, 147, 249)',
  secondary: 'rgb(56, 58, 89)',
  tertiary: 'rgb(98, 114, 164)',
  darker: 'rgb(30, 32, 41)',
  dark: 'rgb(40, 42, 54)',
  grey: 'rgb(125, 126, 131)',
  light: 'rgb(248, 248, 242)',
  medium: 'rgb(49, 51, 65)',
  muted: 'rgb(68, 71, 90)',
  danger: 'rgb(255, 85, 85)',
  success: 'rgb(80, 250, 123)',
  info: 'rgb(139, 233, 253)',
  warning: 'rgb(255, 184, 108)',
  black: 'rgb(0, 0, 0)',
  navSelect: '#2e303d',
  transparent: 'transparent',
};

const theme = {
  colours: colours,

  link: {
    colour: colours.primary,
    hover: {
      colour: lighten(0.2, colours.primary),
    },
  },

  sizes: ['0', '1rem', '2rem', '3rem', '4rem', '5rem', '6rem'],

  breaks: {
    xs: '0px',
    sm: '760px',
    md: '1000px',
    lg: '1600px',
    xl: '2000px',
  },

  zOrder: {
    alert: 1,
    sideBar: 2,
    bottomBar: 3,
    tag: 4,
    backdrop: 5,
    loader: 6,
    loadScreen: 7,
    bottomNavButton: 8,
    dialog: 15,
  },

  borderRadius: '6px',
  boxShadow: '0 1px 3px 1px rgba(0, 0, 0, 0.2)',

  fontFamily:
    "'Montserrat', 'Century Gothic', 'Roboto', 'Arial', 'Calibri', 'Helvetica', sans-serif",

  body: {
    background: colours.dark,
    colour: colours.light,
  },

  buttons: {
    solid: {},
    outline: {},
  },
};

for (var colour in colours) {
  let luminance = getLuminance(colours[colour]);
  let text = luminance > 170 ? colours.dark : colours.light;
  let textInverted = luminance <= 170 ? colours.dark : colours.light;
  let darkened = desaturate(0.1, darken(0.2, colours[colour]));
  let shadowed = transparentize(0.5, lighten(0.3, colours[colour]));

  theme.buttons.solid[colour] = {
    border: colours[colour],
    background: colours[colour],
    text: text,
    active: {
      border: darkened,
      background: darkened,
      text: text,
      shadow: shadowed,
    },
    hover: {
      border: darkened,
      background: darkened,
      text: text,
    },
  };

  theme.buttons.outline[colour] = {
    border: colours[colour],
    background: colours.transparent,
    text: colours[colour],
    active: {
      border: darkened,
      background: darkened,
      text: textInverted,
      shadow: shadowed,
    },
    hover: {
      border: darkened,
      background: colours.transparent,
      text: darkened,
    },
  };
}

export default theme;

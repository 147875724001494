import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Logo from '../../assets/images/app-logo.svg';

import AppHeader from './AppHeader';
import TitleBlock from '../ui/TitleBlock';
import AppTitle from '../ui/AppTitle';
import PageTitle from '../ui/PageTitle';
import AppLogo from '../ui/AppLogo';

import { useAnimation } from 'framer-motion';
import { phase } from '../../lib/animations';

function Header() {
  const pageTitle = useSelector((state) => state.app.title);
  const anim = useAnimation();

  useEffect(() => {
    (async () => {
      await anim.start('exit');
      await anim.start('hidden');
      await anim.start('visible');
    })();
    // eslint-disable-next-line
  }, [pageTitle]);

  return (
    <AppHeader>
      <TitleBlock>
        <AppTitle>net-realms</AppTitle>
        <PageTitle variants={phase} initial='hidden' animate={anim} exit='exit'>
          {pageTitle}
        </PageTitle>
      </TitleBlock>
      <AppLogo>
        <img src={Logo} alt='net-realms' className='app-img' />
      </AppLogo>
    </AppHeader>
  );
}

export default Header;

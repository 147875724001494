import { createGlobalStyle, css } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    height: 100vh;
    width: 100%;
    font-size: 16px;
  }

  body {
    font-family: ${(props) => props.theme.fontFamily};
    background-color: ${(props) => props.theme.body.background};
    color: ${(props) => props.theme.body.colour};
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    main {
      flex: 1 0 auto;
    }

    footer {
      flex-shrink: 0;
    }
  }

  a {
    color: ${(props) => props.theme.link.colour};
    text-decoration: none;

    &:hover {
      color: ${(props) => props.theme.link.hover.colour};
      text-decoration: underline;
    }
  }

  p {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
  }
  h2 {
    font-size: 1.3rem;
    margin-bottom: 2rem;
  }
  h3 {
    font-size: 1.17rem;
    margin-bottom: 0.5rem;
  }
  h4 {
    font-size: 1.2rem;
  }
  h5 {
    font-size: 1rem;
  }
  h6 {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: underline;
  }

  ${(props) =>
    props.theme.sizes.map((s, i) => {
      return css`
        .p-${i} {
          padding-top: ${s};
          padding-left: ${s};
          padding-bottom: ${s};
          padding-right: ${s};
        }

        .py-${i} {
          padding-top: ${s};
          padding-bottom: ${s};
        }

        .px-${i} {
          padding-left: ${s};
          padding-right: ${s};
        }

        .pt-${i} {
          padding-top: ${s};
        }

        .pb-${i} {
          padding-bottom: ${s};
        }

        .pl-${i} {
          padding-left: ${s};
        }

        .pr-${i} {
          padding-right: ${s};
        }

        .m-${i} {
          margin-top: ${s};
          margin-left: ${s};
          margin-bottom: ${s};
          margin-right: ${s};
        }

        .my-${i} {
          margin-top: ${s};
          margin-bottom: ${s};
        }

        .mx-${i} {
          margin-left: ${s};
          margin-right: ${s};
        }

        .mt-${i} {
          margin-top: ${s};
        }

        .mb-${i} {
          margin-bottom: ${s};
        }

        .ml-${i} {
          margin-left: ${s};
        }

        .mr-${i} {
          margin-right: ${s};
        }
      `;
    })}
`;

export default GlobalStyle;

import userTypes from '../actions/userTypes';

const initialState = { loggedIn: false };

export default function authentication(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case userTypes.LOGIN_REQUEST:
      return { loggingIn: true, user: payload.user };
    case userTypes.LOGIN_SUCCESS:
      return { loggedIn: true, loggingIn: false };
    case userTypes.LOGIN_FAILURE:
      return { loggedIn: false, loggingIn: false };
    case userTypes.LOGOUT:
      return { loggedIn: false, loggingIn: false };
    default:
      return state;
  }
}

// Users realm overview page
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import appActions from '../actions/appActions';
import realmActions from '../actions/realmActions';

import PageContent from '../components/layout/PageContent';
import Loader from '../components/loaders/Loader';
import ResourceItem from '../components/realm/ResourceItem';
import Panel from '../components/ui/Panel';

import { motion } from 'framer-motion';

const Overview = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const { loading, selected, summary, error } = useSelector(
    (state) => state.realm
  );

  useEffect(() => {
    dispatch(appActions.setPage('Overview', true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!selected && error) return;

    // Auto select the users realm and load it...
    if (user.loggedIn) {
      if (selected) {
        dispatch(realmActions.getSummary(selected));
      } else {
        dispatch(realmActions.getCurrent(user.id));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected]);

  return (
    <PageContent>
      {error && !selected ? (
        <motion.span exit='undefined'>
          <Redirect to='/realm/select' />
        </motion.span>
      ) : loading || !summary ? (
        <Loader message='Loading...' />
      ) : (
        <>
          <Panel>
            <h3>The Realm of {summary?.name} - Overview</h3>
            <h5>{summary?.ruler}</h5>
          </Panel>
          <Panel>
            <h2>Resources</h2>
            {Object.keys(summary.resources).map((r) => {
              return (
                <ResourceItem
                  key={r}
                  icon={<i className='fas fa-question-circle'></i>}
                  name={r}
                  value={summary.resources[r]}
                />
              );
            })}
          </Panel>
          <Panel>
            <h2>Buildings</h2>
            {Object.keys(summary.buildings).map((r) => {
              return (
                <ResourceItem
                  key={r}
                  icon={<i className='fas fa-question-circle'></i>}
                  name={r}
                  value={summary.buildings[r]}
                />
              );
            })}
          </Panel>
          <Panel>
            <h2>Units</h2>
            {Object.keys(summary.units).map((r) => {
              return (
                <ResourceItem
                  key={r}
                  icon={<i className='fas fa-question-circle'></i>}
                  name={r}
                  value={summary.units[r]}
                />
              );
            })}
          </Panel>
        </>
      )}
    </PageContent>
  );
};

export default Overview;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import userActions from '../actions/userActions';
import appActions from '../actions/appActions';

import PageContent from '../components/layout/PageContent';
import Panel from '../components/ui/Panel';
import Phaser from '../components/ui/Phaser';
import StackedForm from '../components/ui/StackedForm';
import Button from '../components/ui/Button';
import BigButton from '../components/ui/BigButton';
import TextBox from '../components/ui/TextBox';
import Loader from '../components/loaders/Loader';

const validationSchema = yup.object().shape({
  token: yup.string().required(),
});

const Activate = () => {
  const dispatch = useDispatch();
  const { loading, error, activated } = useSelector(
    (state) => state.activation
  );
  const { token: paramToken } = useParams();
  const { register, handleSubmit, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  const [token, setToken] = useState(paramToken || '');

  useEffect(() => {
    dispatch(appActions.setPage('Activate Account'));
    if (token && token.length) {
      dispatch(userActions.activate(token));
    }
    // eslint-disable-next-line
  }, []);

  function onSubmit(data) {
    setToken(data.token);
    dispatch(userActions.activate(data.token));
  }

  const form = (
    <StackedForm onSubmit={handleSubmit(onSubmit)}>
      <TextBox
        id='token'
        name='token'
        disabled={loading}
        defaultValue={token}
        label='activation token'
        error={errors.token}
        ref={register}
      />
      <Phaser>
        <BigButton type='submit' disabled={errors.token}>
          Activate
        </BigButton>
      </Phaser>
    </StackedForm>
  );

  return (
    <PageContent>
      {loading ? (
        <Loader label='Activating...' />
      ) : error ? (
        <Panel>
          <h2 className='danger'>Oops! Failed to Activate!</h2>
          <p>
            There may have been a communication error or the activation code may
            have expired or already been activated. Otherwise, try pasting the
            activation code below and submit again.
          </p>
          {form}
        </Panel>
      ) : activated ? (
        <Panel>
          <h2>Account activated!</h2>
          <p>You can now login and join a game.</p>
          <Button as={Link} to='/login'>
            Login
          </Button>
        </Panel>
      ) : (
        <Panel>
          <h2>Activate your account</h2>
          <p>
            Enter your activation code below and click submit to activate your
            account.
          </p>
          {form}
        </Panel>
      )}
    </PageContent>
  );
};

export default Activate;

// Lists open games for users to join
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import appActions from '../actions/appActions';
import gameActions from '../actions/gameActions';
import userActions from '../actions/userActions';

import PageContent from '../components/layout/PageContent';
import GameList from '../components/games/GameList';
import Loader from '../components/loaders/Loader';
import Panel from '../components/ui/Panel';
import AlertPanel from '../components/ui/AlertPanel';

const Games = () => {
  const dispatch = useDispatch();
  const { id: userId } = useSelector((state) => state.user);
  const games = useSelector((state) => state.games.items.map(game => {
    let registered = state.user.realms.find(realm => `${realm.gameId}` === `${game.id}`);
    return { ...game, registered };
  }));
  const { loading, updated, error } = useSelector((state) => state.games);

  useEffect(() => {
    dispatch(appActions.setPage('Games'));
    dispatch(gameActions.getAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userId) {
      dispatch(userActions.getRealms(userId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId])

  return (
    <PageContent>
      <Panel>
        <h2>Current Games</h2>
        <p className='mt-1'>
          Click join next to an active game below to start playing.
        </p>
      </Panel>
      {error ? <AlertPanel colour='danger' message={error}></AlertPanel> : (
      loading && updated === null ? (
        <Loader label='Getting games...' />
      ) : (
        <GameList games={games} fetching={loading} />
      ))}
    </PageContent>
  );
};

export default Games;

import styled from 'styled-components';
import { motion } from 'framer-motion';
import { phaseUp } from '../../lib/animations';

const InputGroup = styled(motion.div).attrs(() => ({
  variants: phaseUp,
}))`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1.5rem;
`;

export default InputGroup;

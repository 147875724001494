import styled from 'styled-components';

const ValidationError = styled.div`
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  color: ${(props) => props.theme.colours.danger};
  padding: 0.2rem 0;
  border-radius: 0;
  width: 100%;
  display: 'block';
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  text-align: right;
  justify-content: flex-end;
  align-items: flex-end;
  background-color: transparent;
  user-select: none;
  pointer-events: none;
`;

export default ValidationError;

import gameTypes from '../actions/gameTypes';

const initialState = {
  loading: false,
  error: null,
  request: null,
  realm: null,
};

export default function join(state = initialState, { type, payload }) {
  switch (type) {
    case gameTypes.JOIN_REQUEST:
      return {
        ...state,
        realm: null,
        request: { ...payload },
        loading: true,
        error: null,
      };
    case gameTypes.JOIN_SUCCESS:
      return {
        ...state,
        realm: payload.realm,
        request: null,
        loading: false,
        error: null,
      };
    case gameTypes.JOIN_FAILURE:
      return {
        ...state,
        realm: null,
        request: null,
        loading: false,
        error: payload.error,
      };
    default:
      return state;
  }
}

import styled from 'styled-components';

const NavTag = styled.span`
  display: flex;
  position: fixed;
  opacity: 0;
  font-family: ${(props) => props.theme.fontFamily};
  border-radius: ${(props) => props.theme.borderRadius};
  background-color: ${(props) => props.theme.colours.tertiary};
  color: #ffffff;
  padding: 0.5rem 1rem;
  transform: translateX(60%) scale(0.5);
  transition: 0.2s ease-in-out;
  z-index: ${(props) => props.theme.zOrder.tag};
  width: fit-content;
  pointer-events: none;
  user-select: none;
  transform-origin: left;

  &::before {
    content: ' ';
    display: block;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.theme.colours.tertiary};
    transform-origin: center;
    position: relative;
    top: 5px;
    left: -20px;
    transform: rotateZ(45deg);
  }
`;

export default NavTag;

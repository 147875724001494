import styled from 'styled-components';

import { motion } from 'framer-motion';
import { dialog } from '../../lib/animations';

const DialogBox = styled(motion.div).attrs((props) => ({
  variants: props.variants || dialog,
  initial: props.initial || 'hidden',
  animate: props.animate || 'visible',
  exit: props.exit || 'exit',
}))`
  display: flex;
  flex-direction: column;
  width: 40%;
  position: fixed;
  top: 30%;
  left: 30%;
  z-index: ${(props) => props.theme.zOrder.dialog};
  background-color: ${(props) => props.theme.colours.darker};
  border-radius: 5px;
  box-shadow: 0 2px 5px 5px rgba(0, 0, 0, 30%);
  padding-top: 2rem;
  text-align: center;
  transition: 200ms;

  @media (max-width: ${(props) => props.theme.breaks.lg}) {
    width: 50%;
    left: 25%;

    @media (max-width: ${(props) => props.theme.breaks.md}) {
      width: 70%;
      left: 15%;

      @media (max-width: ${(props) => props.theme.breaks.sm}) {
        width: 90%;
        left: 5%;
      }
    }
  }
`;

export default DialogBox;

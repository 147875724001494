import styled from 'styled-components';

import { motion } from 'framer-motion';

const PageTitle = styled(motion.h1)`
  transform-origin: center;
  font-size: 3rem;
  font-weight: 400;
  color: ${(props) => props.theme.colours.light};

  @media (max-width: ${(props) => props.theme.breaks.sm}) {
    font-size: 2.5rem;
  }
`;

export default PageTitle;

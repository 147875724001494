import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import userActions from '../../actions/userActions';
import AppLoader from '../loaders/AppLoader';

const Session = (props) => {
  const { loggedIn, hydrated } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  function updateAuth() {
    const session = window.localStorage.getItem('session');
    const isLoggedIn = typeof session !== 'undefined' && session !== null;
    if (!isLoggedIn && loggedIn) {
      dispatch(userActions.logout());
    } else if (!loggedIn && isLoggedIn) {
      dispatch(userActions.restore());
    }
  }

  useEffect(() => {
    dispatch(userActions.restore());
    window.addEventListener('storage', updateAuth);

    return () => {
      window.removeEventListener('storage', updateAuth);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !hydrated ? <AppLoader /> : <>{props.children}</>;
};

export default Session;

import config from '../config';
import axios from '../lib/axios';

const userService = {
  login,
  logout,
  restore,
  register,
  activate,
  getAll,
  getById,
  update,
  destroy,
  forgot,
  reset,
  getRealms,
};

function restore() {
  return axios
    .get('/users/me')
    .then((response) => {
      const user = response.data.user;

      return { ...user, loggedIn: true };
    })
    .catch((response) => {
      if (response.status !== 401) {
        return handleErrors(response);
      }
      return Promise.resolve();
    });
}

function login(email, password, remember) {
  return axios
    .post('/auth/login', { email, password, remember })
    .then((response) => {
      const user = response.data.user;
      return { ...user, loggedIn: true };
    })
    .catch(handleErrors);
}

function getRealms(userId) {
  return axios
    .get(`/users/${userId}/realms`)
    .then((response) => response.data.realms)
    .catch(handleErrors);
}

function logout() {
  return axios.post('/auth/logout');
}

function getAll() {
  return axios.get('/users').then(handleResponse);
}

function getById(id) {
  return axios.get(`/users/${id}`).then(handleResponse);
}

function register(user) {
  const data = { ...user, activate_url: config.ACTIVATE_URL };

  return axios.post('/auth/register', data).catch(handleErrors);
}

function activate(token) {
  return axios
    .post('/auth/activate', { token: token })
    .then(handleResponse)
    .catch(handleErrors);
}

function forgot(email) {
  const data = {
    email,
    reset_url: config.RESET_URL,
    resend: true,
  };

  return axios.post('/auth/recover', data).catch(handleErrors);
}

function reset(email, token, password) {
  const data = {
    email,
    token,
    password,
  };

  return axios.post('/auth/reset-password', data).catch(handleErrors);
}

function update(user) {
  return axios
    .post(`/users/${user.id}`, user)
    .then(handleResponse)
    .catch(handleErrors);
}

function destroy(id) {
  return axios.delete(`/users/${id}`).then(handleResponse).catch(handleErrors);
}

function handleErrors(error) {
  if (error.response === undefined) {
    return Promise.reject({
      status: 500,
      message: 'Error communicating with server...',
    });
  }

  const errorMessage =
    error.response.data?.message || error?.message || error.response.statusText;

  return Promise.reject({
    status: error.response.status,
    message: errorMessage,
  });
}

function handleResponse(response) {
  return response.data;
}

export default userService;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import userActions from '../../actions/userActions';
import Dialog from '../ui/Dialog';
import NavTag from './NavTag';

const Logout = (props) => {
  const dispatch = useDispatch();

  const [showDialog, setShowDialog] = useState(false);

  function showLogoutDialog() {
    setShowDialog(true);
  }

  function closeLogoutDialog() {
    setShowDialog(false);
  }

  function logout() {
    setShowDialog(false);
    dispatch(userActions.logout());
  }

  return (
    <>
      <Dialog
        show={showDialog}
        onClose={closeLogoutDialog}
        onSuccess={logout}
        successText='Logout'
        dangerText='Cancel'
        title='Log out...'
        message='Are you sure you want to log out?'
      />
      <button onClick={showLogoutDialog}>
        {props.children}
        <NavTag>logout</NavTag>
      </button>
    </>
  );
};

export default Logout;

// flex-block bg-dark py-2 mt-1 text-muted.
import styled from 'styled-components';

const AppFooter = styled.footer`
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  height: auto;
  background-color: ${(props) => props.theme.colours.dark};
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 1rem;
  color: ${(props) => props.theme.colours.muted};
  transition: 200ms;

  @media (max-width: ${(props) => props.theme.breaks.sm}) {
    padding-bottom: 8rem;
  }
`;

export default AppFooter;

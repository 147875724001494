import styled from 'styled-components';

import { motion } from 'framer-motion';
import { logo } from '../../lib/animations';

const AppLogo = styled(motion.div).attrs((props) => ({
  variants: props.variants || logo,
  initial: props.hidden || 'hidden',
  animate: props.animate || 'visible',
  exit: props.exit || 'exit',
}))`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: right;
  margin: 0.5rem auto 0;

  img {
    display: inline-block;
    height: 6rem;

    @media (max-width: ${(props) => props.theme.breaks.sm}) {
      height: 5rem;
    }
  }
`;

export default AppLogo;

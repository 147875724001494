import styled from 'styled-components';

const LoadContent = styled.div`
  position: relative;
  display: flex;
  margin-top: 20px;
  background-color: rgba(0, 0, 0, 20%);
  border-radius: 15px;
  padding: 0 1rem;
  overflow: hidden;
  height: 200px;
  width: 200px;

  .label {
    display: flex;
    position: absolute;
    bottom: 1rem;
    left: 0;
    text-align: center;
    width: 100%;
    justify-content: center;
    align-content: center;
    font-weight: 600;
  }
`;

export default LoadContent;

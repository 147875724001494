import styled, { css } from 'styled-components';

const DialogButton = styled.button`
  border: none;
  box-shadow: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  width: 50%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 1.2rem;
  transition: 0.2s ease-in-out;
  cursor: pointer;

  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.2),
      inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }

  &:first-child {
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-bottom-left-radius: 0;
  }

  ${({ theme, variant, colour }) =>
    variant &&
    theme.buttons[variant][colour] &&
    css`
      border-color: ${theme.buttons[variant][colour].border};
      background-color: ${theme.buttons[variant][colour].background};
      color: ${theme.buttons[variant][colour].text};

      &:hover {
        border-color: ${theme.buttons[variant][colour].hover.border};
        background-color: ${theme.buttons[variant][colour].hover.background};
        color: ${theme.buttons[variant][colour].hover.text};
      }
    `}
`;

export default DialogButton;

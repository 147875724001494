import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from '../actions/appActions';
import realmActions from '../actions/realmActions';
import userActions from '../actions/userActions';
import PageContent from '../components/layout/PageContent';
import Panel from '../components/ui/Panel';

import RealmSelectItem from '../components/realm/RealmSelectItem';

const RealmSelect = () => {
  const dispatch = useDispatch();
  const { id: userId, realms } = useSelector((state) => state.user);
  const selectedRealm = useSelector((state) => `${state.realm.selected}`);

  useEffect(() => {
    dispatch(appActions.setPage('Realm Select'));
    // Query users realms
    dispatch(userActions.getRealms(userId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectRealm(id) {
    dispatch(realmActions.selectRealm(id));
  }

  return (
    <PageContent>
      <Panel>
        <h3>Realm Select</h3>
        <p>Select a Realm to play.</p>
      </Panel>
      {realms.map((realm) => (
        <RealmSelectItem
          key={realm.id}
          realm={realm}
          selected={realm.id === selectedRealm}
          onSelect={selectRealm}
        ></RealmSelectItem>
      ))}
    </PageContent>
  );
};

export default RealmSelect;

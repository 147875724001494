import styled from 'styled-components';

import { motion } from 'framer-motion';
import { container } from '../../lib/animations';

const ProfileTitle = styled(motion.div).attrs(() => ({
  variants: { container },
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}))`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  padding-left: 1rem;
  padding-top: 2.5rem;
  transition: 200ms;
  overflow: hidden;

  @media (max-width: 550px) {
    padding-top: 1.8rem;
  }
`;

export default ProfileTitle;

// Game registration screen for users to choose realm name
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import appActions from '../actions/appActions';
import PageContent from '../components/layout/PageContent';
import Panel from '../components/ui/Panel';
import StackedForm from '../components/ui/StackedForm';
import TextBox from '../components/ui/TextBox';
import BigButton from '../components/ui/BigButton';
import Loader from '../components/loaders/Loader';
import { AnimatePresence, motion } from 'framer-motion';
import BrandText from '../components/ui/BrandText';
import gameActions from '../actions/gameActions';
import AlertPanel from '../components/ui/AlertPanel';
import userActions from '../actions/userActions';

const validationSchema = yup.object().shape({
  realmName: yup
    .string()
    .required('realm name is required')
    .min(2, 'realm name must be 2 to 30 characters')
    .max(30, 'realm name must be 2 to 30 characters'),
  rulerName: yup
    .string()
    .test(
      'min-length',
      'ruler name must be at least 2 characters',
      (value) => value === '' || value.length >= 2
    )
    .max(30, 'ruler name cannot exceed 30 characters'),
});

const Join = () => {
  const { id } = useParams();

  const userId = useSelector((state) => state.user.id);
  const displayName = useSelector((state) => state.user.displayName);

  const game = useSelector((state) =>
    state.games.items.find((g) => `${g.id}` === `${id}`)
  );

  const registered = useSelector((state) =>
    state.user.realms.find((realm) => `${realm.gameId}` === `${id}`)
  );

  const gamesLoading = useSelector((state) => state.games.loading);
  const gamesError = useSelector((state) => state.games.error);
  const join = useSelector((state) => state.join);

  const [defaultFields, setDefaultFields] = useState({
    realmName: '',
    rulerName: '',
  });

  const dispatch = useDispatch();

  const { handleSubmit, register, errors, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    dispatch(appActions.setPage('Join Game'));
    dispatch(gameActions.getAll());
    dispatch(userActions.getRealms(userId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onFormSubmit(data) {
    const { realmName, rulerName } = data;

    dispatch(
      gameActions.join(
        id,
        realmName,
        rulerName.length ? rulerName : displayName
      )
    );
  }

  if (!gamesLoading && (gamesError || game?.open === false)) {
    return (
      <motion.span exit='undefined'>
        <Redirect to='/games' />
      </motion.span>
    );
  }

  return (
    <PageContent>
      {registered ? (
        <Panel colour='danger'>
          <h3>Oops... You have already registered for this game...</h3>
          <p>You can only register once for a game.</p>
          <BigButton as={Link} to='/games'>
            Return to Games
          </BigButton>
        </Panel>
      ) : (
        <>
          {join.error ? (
            <AlertPanel key='join-error' message={join.error} colour='danger' />
          ) : null}
          <AnimatePresence exitBeforeEnter>
            {join.loading || gamesLoading ? (
              <Loader label='Loading...' key='loader-component' />
            ) : (
              <Panel initial='slideIn' exit='slideOut' key='main-panel'>
                <h2>
                  Register for <BrandText>{game?.name}</BrandText>
                </h2>
                <p>
                  Choose a Realm Name to rule under. Enter a Ruler name or leave
                  blank to use your display name.
                </p>
                <StackedForm onSubmit={handleSubmit(onFormSubmit)}>
                  <TextBox
                    name='realmName'
                    label='realm name'
                    placeholder='Choose a realm name'
                    onChange={(e) =>
                      setDefaultFields((state) => ({
                        ...state,
                        realmName: e.target.value,
                      }))
                    }
                    defaultValue={defaultFields.realmName}
                    ref={register}
                    error={errors.realmName}
                    disabled={formState.isSubmitting}
                  />
                  <TextBox
                    name='rulerName'
                    label='ruler name'
                    onChange={(e) =>
                      setDefaultFields((state) => ({
                        ...state,
                        rulerName: e.target.value,
                      }))
                    }
                    defaultValue={defaultFields.rulerName}
                    placeholder={displayName}
                    ref={register}
                    error={errors.rulerName}
                    disabled={formState.isSubmitting}
                  />
                  <BigButton
                    type='submit'
                    disabled={errors.realmName || errors.realmRuler}
                  >
                    join game
                  </BigButton>
                  <BigButton as={Link} variant='outline' to='/games'>
                    cancel
                  </BigButton>
                </StackedForm>
              </Panel>
            )}
          </AnimatePresence>
        </>
      )}
    </PageContent>
  );
};

export default Join;

const gameTypes = {
  GETALL_REQUEST: 'GAMES_GETALL_REQUEST',
  GETALL_SUCCESS: 'GAMES_GETALL_SUCCESS',
  GETALL_FAILURE: 'GAMES_GETALL_FAILURE',

  JOIN_REQUEST: 'GAMES_JOIN_REQUEST',
  JOIN_SUCCESS: 'GAMES_JOIN_SUCCESS',
  JOIN_FAILURE: 'GAMES_JOIN_FAILURE',
};

export default gameTypes;

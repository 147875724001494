import appTypes from './appTypes';

const appActions = {
  setPage,
  updateTitle,
  setBasicNav,
  setGameNav
};

function setPage(title, gameNav = false) {
  return { type: appTypes.SET_PAGE, payload: { title, gameNav } };
}

function updateTitle(title) {
  return { type: appTypes.PAGE_TITLE, payload: { title } };
}

function setBasicNav() {
  return { type: appTypes.SET_BASIC_NAV, payload: {} };
}

function setGameNav() {
  return { type: appTypes.SET_GAME_NAV, payload: {} };
}

export default appActions;

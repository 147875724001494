import styled from 'styled-components';

import { motion } from 'framer-motion';
import { glideLeft } from '../../lib/animations';

const ProfileName = styled(motion.div).attrs(() => ({
  variants: glideLeft,
}))`
  font-size: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid ${(props) => props.theme.colours.muted};
  transition: 200ms;
  overflow: hidden;

  @media (max-width: 550px) {
    font-size: 1.5rem;
  }
`;

export default ProfileName;

import React from 'react';
import { Switch, useLocation, useRouteMatch } from 'react-router-dom';

import PrivateRoute from '../components/util/PrivateRoute';

import Games from '../pages/Games';
import Join from '../pages/Join';

const GameRoutes = () => {
  const location = useLocation();
  const match = useRouteMatch();

  return (
    <Switch location={location} key={location.key}>
      <PrivateRoute path={`${match.url}/:id/join`} component={Join} />
      <PrivateRoute path={`${match.url}/`} component={Games} />
    </Switch>
  );
};

export default GameRoutes;

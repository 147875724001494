import styled from 'styled-components';
import { motion } from 'framer-motion';
import { panel } from '../../lib/animations';

const Panel = styled(motion.div).attrs((props) => ({
  variants: props.variants || panel,
  initial: props.initial || 'hidden',
  animate: props.animate || 'visible',
  exit: props.exit || 'exit',
  colour: props.colour || 'primary',
}))`
  padding: 1rem 1.5rem;
  border-radius: ${(props) => props.theme.borderRadius};
  border-top-width: 3px;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-top-color: ${(props) => props.theme.colours[props.colour]};
  background-color: ${(props) => props.theme.colours.darker};
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 30%);
  margin-bottom: 2rem;
`;

export default Panel;

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import appActions from '../actions/appActions';
import userActions from '../actions/userActions';

import PageContent from '../components/layout/PageContent';
import TextBox from '../components/ui/TextBox';
import Loader from '../components/loaders/Loader';
import BigButton from '../components/ui/BigButton';
import StackedForm from '../components/ui/StackedForm';
import Panel from '../components/ui/Panel';
import Phaser from '../components/ui/Phaser';
import Text from '../components/ui/Text';

const Forgot = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const loading = useSelector((state) => state.recover.loading);
  const success = useSelector((state) => state.recover.success);

  useEffect(() => {
    dispatch(appActions.setPage('Reset Password'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onFormSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    dispatch(userActions.recover(email));
  }

  function onInputChange(event) {
    const { value } = event.target;
    setEmail(value);
  }

  return (
    <PageContent>
      {submitted && success ? (
        <div className='panel bg-darker'>
          <h3 className='text-success'>
            <i className='fas fa-check'></i> Success!
          </h3>
          <p>
            Check your email for further instructions to reset your password.
          </p>
        </div>
      ) : loading ? (
        <Loader label='Loading...' />
      ) : (
        <Panel>
          <h3>Forgot your password?</h3>
          <Text left>
            No worries, to reset your password, enter the email you registered
            with below.
          </Text>
          <StackedForm onSubmit={onFormSubmit}>
            <TextBox
              name='email'
              onChange={onInputChange}
              value={email}
              disabled={false}
              label='email'
              error={submitted && !email}
            />
            <Phaser>
              <BigButton type='submit' disabled={!email || loading}>
                submit
              </BigButton>
            </Phaser>
            <Phaser className='pt-1'>
              Remembered? <Link to='/login'>Back to Login</Link>
            </Phaser>
          </StackedForm>
        </Panel>
      )}
    </PageContent>
  );
};

export default Forgot;

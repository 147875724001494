import styled from 'styled-components';

import { motion } from 'framer-motion';

const ProfileAvatar = styled(motion.div).attrs(() => ({
  initial: {
    rotate: -110,
    clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
  },
  animate: {
    rotate: 0,
    clipPath: 'polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%)',
    transition: {
      duration: 0.5,
      ease: 'easeIn',
    },
  },
  exit: {
    rotate: -110,
    clipPath: 'polygon(50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%, 50% 50%)',
    transition: {
      duration: 0.1,
    },
  },
}))`
  opacity: 1;
  width: 200px;
  height: 200px;
  clip-path: polygon(50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%, 5% 25%);
  transition: 200ms;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 550px) {
    width: 120px;
    height: 120px;
  }
`;

export default ProfileAvatar;

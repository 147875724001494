import userTypes from '../actions/userTypes';

export default function reset(state = {}, { type }) {
  switch (type) {
    case userTypes.RESET_REQUEST:
      return { loading: true, success: false };
    case userTypes.RESET_SUCCESS:
      return { loading: false, success: true };
    case userTypes.RESET_FAILURE:
      return { loading: false, success: false };
    default:
      return state;
  }
}

import styled from 'styled-components';

import { motion } from 'framer-motion';
import { container } from '../../lib/animations';

const StackedForm = styled(motion.form).attrs((props) => ({
  variants: container,
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}))`
  position: relative;
  top: 0;
  left: 0;
  max-width: 500px;
  width: 100%;
  text-align: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 4rem;
  border-radius: 1rem;
  padding: 1rem 1rem;
`;

export default StackedForm;

import userTypes from '../actions/userTypes';

export default function recover(state = {}, { type }) {
  switch (type) {
    case userTypes.RECOVER_REQUEST:
      return { loading: true, success: false };
    case userTypes.RECOVER_SUCCESS:
      return { loading: false, success: true };
    case userTypes.RECOVER_FAILURE:
      return { loading: false, success: false };
    default:
      return state;
  }
}

import userTypes from './userTypes';
import userService from '../services/userService';
import alertActions from './alertActions';
import history from '../lib/history';

const userActions = {
  activate,
  login,
  logout,
  register,
  recover,
  reset,
  getAll,
  destroy,
  getRealms,
  restore,
};

function restore() {
  return (dispatch) => {
    dispatch(request());

    userService.restore().then(
      (user) => {
        dispatch(success(user));
      },
      (error) => {
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: userTypes.RESTORE_REQUEST, payload: {} };
  }
  function success(user) {
    return { type: userTypes.RESTORE_SUCCESS, payload: { user } };
  }
  function failure(error) {
    return { type: userTypes.RESTORE_FAILURE, payload: { error } };
  }
}

function login(email, password, remember, from) {
  return (dispatch) => {
    dispatch(request({ email }));

    userService.login(email, password, remember).then(
      (user) => {
        dispatch(success(user));
        history.push(from);
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(user) {
    return { type: userTypes.LOGIN_REQUEST, payload: { user } };
  }
  function success(user) {
    return { type: userTypes.LOGIN_SUCCESS, payload: { user } };
  }
  function failure(error) {
    return { type: userTypes.LOGIN_FAILURE, payload: { error } };
  }
}

function logout() {
  return async (dispatch) => {
    try {
      await userService.logout();
      dispatch({ type: userTypes.LOGOUT });
    } catch (err) {
      dispatch(alertActions.error(err.message));
    }
  };
}

function getRealms(userId) {
  return (dispatch) => {
    dispatch(request());

    userService.getRealms(userId).then(
      (realms) => {
        dispatch(success(realms));
      },
      (error) => {
        if (error.status === 401) {
          dispatch(userActions.logout());
        }
        dispatch(failure(error.message));
      }
    );
  };

  function request() {
    return { type: userTypes.REALMS_REQUEST, payload: {} };
  }
  function success(realms) {
    return { type: userTypes.REALMS_SUCCESS, payload: { realms } };
  }
  function failure(error) {
    return { type: userTypes.REALMS_FAILURE, payload: { error } };
  }
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (response) => {
        dispatch(success());
        history.push('/register/success');
        dispatch(alertActions.success('Registration successful'));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(user) {
    return { type: userTypes.REGISTER_REQUEST, payload: { user } };
  }
  function success(user) {
    return { type: userTypes.REGISTER_SUCCESS, payload: {} };
  }
  function failure(error) {
    return { type: userTypes.REGISTER_FAILURE, payload: { error } };
  }
}

function recover(email) {
  return (dispatch) => {
    dispatch(request());

    userService.forgot(email).then(
      (response) => {
        dispatch(success());
        dispatch(
          alertActions.success(
            'Reset email sent: Check your email to continue resetting your password...'
          )
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: userTypes.RECOVER_REQUEST, payload: {} };
  }

  function success() {
    return { type: userTypes.RECOVER_SUCCESS, payload: {} };
  }

  function failure(error) {
    return { type: userTypes.RECOVER_FAILURE, payload: { error } };
  }
}

function reset({ email, token, password }) {
  return (dispatch) => {
    dispatch(request());

    userService.reset(email, token, password).then(
      (response) => {
        dispatch(success());
        dispatch(
          alertActions.success(
            'Your password has successfully been reset. You may now log it!'
          )
        );
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request() {
    return { type: userTypes.RESET_REQUEST, payload: {} };
  }

  function success() {
    return { type: userTypes.RESET_SUCCESS, payload: {} };
  }

  function failure(error) {
    return { type: userTypes.RESET_FAILURE, payload: { error } };
  }
}

function activate(token) {
  return (dispatch) => {
    dispatch(request(token));

    userService.activate(token).then(
      (response) => {
        dispatch(success());
        dispatch(alertActions.success('Account activated. You can now login!'));
      },
      (error) => {
        dispatch(failure(error.message));
        dispatch(alertActions.error(error.message));
      }
    );
  };

  function request(token) {
    return { type: userTypes.ACTIVATE_REQUEST, payload: { token } };
  }

  function success() {
    return { type: userTypes.ACTIVATE_SUCCESS, payload: {} };
  }

  function failure(error) {
    return { type: userTypes.ACTIVATE_FAILURE, payload: { error } };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());

    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error.message))
    );
  };

  function request() {
    return { type: userTypes.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userTypes.GETALL_SUCCESS, payload: { users } };
  }
  function failure(error) {
    return { type: userTypes.GETALL_FAILURE, payload: { error } };
  }
}

function destroy(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.destroy(id).then(
      (user) => dispatch(success(id)),
      (error) => dispatch(failure(id, error.message))
    );
  };

  function request(id) {
    return { type: userTypes.DELETE_REQUEST, payload: { id } };
  }
  function success(id) {
    return { type: userTypes.DELETE_SUCCESS, payload: { id } };
  }
  function failure(id, error) {
    return { type: userTypes.DELETE_FAILURE, payload: { id, error } };
  }
}

export default userActions;

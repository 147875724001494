import React from 'react';
import styled from 'styled-components';

import { motion } from 'framer-motion';

const StyledAlertPanel = styled(motion.div).attrs(props => ({ colour: props.colour || 'info', variants: {
  hidden: {
    opacity: 0,
    filter: 'blur(5px)',
    transition: {
      duration: 0.3,
      ease: 'easeIn'
    }
  }, visible: {
    filter: 'blur(0px)',
    opacity: 1,
    transition: {
      duration: 0.3,
      ease: 'easeIn'
    }
  }
}, initial: 'hidden', animate: 'visible', exit: 'hidden' }))`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  text-align: left;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1rem;
  padding-left: 1.2rem;
  padding-right: 5rem;
  padding-bottom: 1rem;
  transition: 200ms;
  width: 100%;
  background-color: ${props => props.theme.colours[props.colour]};
  color: ${props => props.theme.colours.light};
  margin-bottom: 1rem;
  box-shadow: ${props => props.theme.boxShadow};
  border-radius: 2px;

  p {
    margin: auto;
    width: 100%;
    text-align: left;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    padding: 0.5rem;
    background-color: transparent;
    position: absolute;
    top: 5px;
    right: 1rem;
    transition: 200ms;
    cursor: pointer;
    color: ${props => props.theme.colours.light};

    &:hover {
      color: ${props => props.theme.colours.dark};
    }

    &:active {
      color: ${props => props.theme.colours.grey};
    }

    &:focus, &:active {
      outline: none;
    }
  }
`;

const AlertPanel = (props) => {
  return (
    <StyledAlertPanel colour={props.colour}>
      <p>{props.message}</p>
      {props.onDismiss ? <button onClick={props.onDismiss}><i className='fas fa-times fa-2x'></i></button> : null}
    </StyledAlertPanel>
  )
}

export default AlertPanel;

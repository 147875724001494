export const slideUp = {
  hidden: {
    y: 30,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    y: 30,
    opacity: 0,
  },
};

export const menu = {
  closed: {
    clipPath: 'circle(0px at 48px 93%)',
    transition: {
      delay: 0.5,
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  open: {
    clipPath: 'circle(1000px at 50px 90%)',
  },
  transition: {
    type: 'spring',
    stiffness: 20,
    restDelta: 2,
  },
};

export const logo = {
  hidden: {
    opacity: 0,
    x: 10,
    filter: 'blur(7px)',
    transition: {
      duration: 0,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    filter: ['blur(6px)', 'blur(4px)', 'blur(2px)', 'blur(1px)', 'blur(0)'],
    transition: {
      duration: 1,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    filter: ['blur(1px)', 'blur(2px)', 'blur(3px)', 'blur(4px)', 'blur(6px)'],
    transition: {
      duration: 0.1,
    },
  },
};

export const gameRow = {
  hidden: {
    opacity: 0,
    y: 80,
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    y: 80,
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
};

export const bottomBar = {
  hidden: {
    y: 80,
  },
  visible: {
    y: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    y: 80,
    transition: {
      ease: 'easeOut',
    },
  },
};

export const title = {
  hidden: {
    opacity: 0,
    x: -10,
    letterSpacing: '15px',
  },
  visible: {
    letterSpacing: '1px',
    opacity: 1,
    x: 0,
    transition: {
      duration: 1,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const sideBar = {
  hidden: {
    x: -80,
  },
  visible: {
    x: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    x: -80,
    transition: {
      ease: 'easeOut',
    },
  },
};

export const fadeIn = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
};

export const form = {
  hidden: {
    y: 50,
    opacity: 0,
  },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    y: 50,
    opacity: 0,
    transition: {
      ease: 'easeOut',
    },
  },
};

export const panel = {
  hidden: {
    y: 50,
    x: 0,
    opacity: 1,
    scale: 1.1,
    boxShadow: '0px 50px 30px 30px rgba(0, 0, 0, 0.2)',
  },
  visible: {
    y: 0,
    x: 0,
    opacity: 1,
    scale: 1,
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.3)',
    transition: {
      duration: 0.3,
      ease: 'easeIn',
    },
  },
  slideIn: {
    x: 150,
    opacity: 0,
    scale: 1,
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.3)',
    transition: {
      duration: 0.6,
      ease: 'easeIn',
    },
  },
  slideOut: {
    x: -150,
    opacity: 0,
    scale: 1,
    boxShadow: '0 1px 2px 2px rgba(0, 0, 0, 0.3)',
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    y: 50,
    x: 0,
    opacity: 0,
    scale: 1.1,
    boxShadow: '0px 50px 30px 30px rgba(0, 0, 0, 0.2)',
    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

export const alertPop = {
  hidden: {
    y: -80,
  },
  visible: {
    y: 0,
    transition: {
      ease: 'easeOut',
    },
  },
  exit: {
    y: -80,
  },
};

export const slideLeft = {
  hidden: {
    x: 50,
  },
  visible: {
    x: 0,
    transition: {
      ease: 'linear',
    },
  },
  exit: {
    x: 50,
  },
};

export const glideLeft = {
  hidden: {
    x: '100%',
  },
  visible: {
    x: 0,
    transition: {
      ease: 'easeIn',
    },
  },
  exit: {
    x: '100%',
  },
};

export const container = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {},
};

export const containerAnimated = {
  hidden: {},
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
  },
};

export const phaseUp = {
  hidden: {
    y: 50,
    opacity: 0,
    filter: 'blur(2px)',
  },
  visible: {
    y: 0,
    opacity: 1,
    filter: ['blur(4px)', 'blur(3px)', 'blur(2px)', 'blur(1px)', 'blur(0)'],
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    y: -50,
    opacity: 0,
    filter: 'blur(5px)',
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
};

export const dialog = {
  hidden: {
    y: 80,
    opacity: 0,
    filter: 'blur(2px)',
  },
  visible: {
    y: 0,
    opacity: 1,
    filter: ['blur(4px)', 'blur(3px)', 'blur(2px)', 'blur(1px)', 'blur(0)'],
    transition: {
      ease: 'easeIn',
      duration: 0.1,
    },
  },
  exit: {
    y: -80,
    opacity: 0,
    filter: 'blur(5px)',
    transformOrigin: 'center',
    transition: {
      duration: 0.3,
    },
  },
};

export const phaseLeft = {
  hidden: {
    x: 50,
    opacity: 0,
    filter: 'blur(5px)',
    transition: {
      duration: 0,
    },
  },
  visible: {
    x: 0,
    opacity: 1,
    filter: ['blur(4px)', 'blur(3px)', 'blur(2px)', 'blur(1px)', 'blur(0)'],
    transition: {
      duration: 0.7,
      ease: 'easeOut',
    },
  },
  exit: {
    x: -50,
    opacity: 0,
    filter: ['blur(1px)', 'blur(2px)', 'blur(3px)', 'blur(4px)', 'blur(5px)'],
    transition: {
      duration: 0.1,
    },
  },
};

export const pageTitle = {
  hidden: {
    x: 50,
  },
  visible: {
    x: 0,
    transition: {
      ease: 'linear',
    },
  },
  exit: {
    x: 50,
  },
};

export const phase = {
  hidden: {
    opacity: 0,
    filter: 'blur(7px)',
    transition: {
      duration: 0,
    },
  },
  visible: {
    opacity: 1,
    filter: ['blur(6px)', 'blur(4px)', 'blur(2px)', 'blur(1px)', 'blur(0)'],
    transition: {
      duration: 0.4,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    filter: ['blur(1px)', 'blur(2px)', 'blur(3px)', 'blur(4px)', 'blur(6px)'],
    transition: {
      duration: 0.1,
    },
  },
};

export const page = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

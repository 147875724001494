import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import appActions from '../actions/appActions';

import PageContent from '../components/layout/PageContent';
import Panel from '../components/ui/Panel';
import BrandText from '../components/ui/BrandText';

const Home = () => {
  // Redux
  const dispatch = useDispatch();
  const { loggedIn, displayName } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(appActions.setPage('Home'));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContent>
      <h2>Welcome to Net-realms!</h2>

      <section>
        <Panel>
          <h3>Overview</h3>
          <p>
            <BrandText>net-realms</BrandText> is an online text-based strategy
            game. It's currently under development, but you can start having a
            look around.
          </p>

          {loggedIn ? (
            <p>Welcome back, {displayName}!</p>
          ) : (
            <p>
              <Link to='/register'>Register</Link> or{' '}
              <Link to='/login'>login</Link> to start playing...
            </p>
          )}
        </Panel>
      </section>
    </PageContent>
  );
};

export default Home;

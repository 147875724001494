import userTypes from '../actions/userTypes';

export default function registration(state = {}, { type }) {
  switch (type) {
    case userTypes.REGISTER_REQUEST:
      return { registering: true, registered: false };
    case userTypes.REGISTER_SUCCESS:
      return { registered: true, registering: false };
    case userTypes.REGISTER_FAILURE:
      return { registering: false, registered: false };
    default:
      return state;
  }
}

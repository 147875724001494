import React from 'react';

import ProfileTitle from './ProfileTitle';
import ProfileName from './ProfileName';
import ProfileRole from './ProfileRole';

const ProfileHeader = (props) => {
  return (
    <ProfileTitle>
      <ProfileName>{props.name}</ProfileName>
      <ProfileRole>{props.role}</ProfileRole>
    </ProfileTitle>
  );
};

export default ProfileHeader;

import alertTypes from '../actions/alertTypes';

export default function alert(state = {}, { type, payload }) {
  switch (type) {
    case alertTypes.SUCCESS:
      return {
        type: 'success',
        message: payload.message,
      };
    case alertTypes.ERROR:
      return {
        type: 'danger',
        message: payload.message,
      };
    case alertTypes.INFO:
      return {
        type: 'info',
        message: payload.message,
      };
    case alertTypes.CLEAR:
      return {};
    default:
      return state;
  }
}

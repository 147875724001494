import appTypes from '../actions/appTypes';

const initialState = { title: 'Loading...', gameNav: false };

export default function app(state = initialState, { type, payload }) {
  switch (type) {
    case appTypes.PAGE_TITLE:
      return {
        ...state,
        title: payload.title,
      };
    case appTypes.SET_PAGE:
      return {
        ...state,
        title: payload.title,
        gameNav: payload.gameNav,
      };
    case appTypes.SET_BASIC_NAV:
      return {
        ...state,
        gameNav: false,
      };
    case appTypes.SET_GAME_NAV:
      return {
        ...state,
        gameNav: true,
      };
    default:
      return state;
  }
}

import styled from 'styled-components';

import { motion } from 'framer-motion';
import { alertPop } from '../../lib/animations';

const BannerAlert = styled(motion.div).attrs((props) => ({
  variants: props.variants || alertPop,
  initial: props.initial || 'hidden',
  animate: props.animate || 'visible',
  exit: props.exit || 'exit',
  key: props.key || 'alert',
}))`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 5rem;
  padding-right: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${(props) => props.theme.zOrder.alert};
  width: 100%;
  min-height: 30px;

  &.success {
    color: #ffffff;
    background-color: ${(props) => props.theme.colours.success};
  }

  &.danger {
    color: #ffffff;
    background-color: ${(props) => props.theme.colours.danger};
  }

  &.info {
    color: #333333;
    background-color: ${(props) => props.theme.colours.info};
  }

  @media (max-width: ${(props) => props.theme.breaks.sm}) {
    padding-left: 1rem;
  }
`;

export default BannerAlert;

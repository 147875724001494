import { useRef, useEffect } from 'react';

const usePortal = () => {
  const portalElemRef = useRef(null);

  useEffect(() => {
    const parentElem = document.getElementById('portal');
    parentElem.appendChild(portalElemRef.current);

    return () => {
      portalElemRef.current.remove();
    };
  }, []);

  function getPortal() {
    if (!portalElemRef.current) {
      portalElemRef.current = document.createElement('div');
    }
    return portalElemRef.current;
  }

  return getPortal();
};

export default usePortal;

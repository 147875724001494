import styled from 'styled-components';

import { motion } from 'framer-motion';
import { bottomBar } from '../../lib/animations';

const AppBottomBar = styled(motion.div).attrs((props) => ({
  variants: bottomBar,
  initial: 'hidden',
  animate: 'visible',
  exit: 'exit',
}))`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  z-index: ${(props) => props.theme.zOrder.bottomBar};
  box-shadow: 0 -1px 3px 3px rgba(0, 0, 0, 0.3);
  background-color: ${(props) => props.theme.colours.darker};
  opacity: 1;
  transition: 200ms;

  @media (min-width: ${(props) => props.theme.breaks.sm}) {
    bottom: -5rem;
    opacity: 0;
    pointer-events: none;
    user-select: none;
  }
`;

export default AppBottomBar;
